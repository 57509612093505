import { AnnaError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";

import {
  AddressAutocompleteResponseData,
  ResolveAutocompleteData,
  type ResolveAutocompleteResponseData,
} from "services/addressSearch/addressSearchTypes";
import { type AddressSearchClient } from "./addressSearchClient";

export type AddressSuggestionsStatus = "idle" | "searching" | "searched";

export class AddressSearchStore {
  private readonly _addressSearchClient: AddressSearchClient;
  private _status: AddressSuggestionsStatus = "idle";

  constructor(addressSearchClient: AddressSearchClient) {
    this._addressSearchClient = addressSearchClient;

    makeAutoObservable(this);
  }

  get status(): AddressSuggestionsStatus {
    return this._status;
  }

  private set status(status: AddressSuggestionsStatus) {
    this._status = status;
  }

  searchAddressByQuery = async (query: string): Promise<AddressAutocompleteResponseData> => {
    try {
      this.status = "searching";
      const data = await this._addressSearchClient.getAddressSuggestions(query);
      this.status = "searched";

      return data;
    } catch (e) {
      console.error(new AnnaError("Failed to load addresses", { cause: e }));

      return new AddressAutocompleteResponseData([]);
    }
  };

  getFullAddress = async (addressId: string): Promise<ResolveAutocompleteResponseData> => {
    return await this._addressSearchClient.getFullAddress(new ResolveAutocompleteData(addressId));
  };
}
