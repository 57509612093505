import { makeAutoObservable } from "mobx";
import { type BillingClient } from "services/billing/billingClient";
import { type FormSubmitResult } from "types/form";

type BillingControllerState = "loading" | "error" | "success";

export class BillingControllerStore {
  private _state: BillingControllerState = "loading";

  constructor(private readonly _billingClient: BillingClient) {
    makeAutoObservable(this);
  }

  get state(): BillingControllerState {
    return this._state;
  }

  set state(value: BillingControllerState) {
    this._state = value;
  }

  async charge(): Promise<FormSubmitResult> {
    const billingResult = await this._billingClient.charge();
    this.state = billingResult.isPaid ? "success" : "error";
  }
}
