import type { FC } from "react";
import { getConfig } from "config/config";
import styles from "./styles.module.scss";

const config = getConfig();

const Footer: FC = () => {
  const email = config.isAU ? "support@annamoney.au" : "formations@anna.money";

  return (
    <div className={styles.footer}>
      If you have any questions or encounter any difficulties during the process, please drop a line to{" "}
      <a className={styles.link} href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  );
};

export default Footer;
