export enum AcnFormStep {
  HOW_IT_WORKS = "ACN_HOW_IT_WORKS",
  CONSENT = "ACN_CONSENT",
  COMPANY = "ACN_COMPANY",
  ABN = "ACN_ABN",
  DIRECTOR = "ACN_DIRECTOR",
  IDENTITY = "ACN_IDENTITY",
  BASKET = "ACN_BASKET",
  CONFIRM = "ACN_CONFIRM",
  CHARGE = "ACN_CHARGE",
  COMPLETE = "ACN_COMPLETE",
}

export const FORM_STEP_TO_PATH: Record<AcnFormStep, string> = {
  [AcnFormStep.HOW_IT_WORKS]: "before-started",
  [AcnFormStep.CONSENT]: "consent",
  [AcnFormStep.COMPANY]: "company",
  [AcnFormStep.ABN]: "abn",
  [AcnFormStep.DIRECTOR]: "director",
  [AcnFormStep.IDENTITY]: "identity",
  [AcnFormStep.BASKET]: "card-details",
  [AcnFormStep.CONFIRM]: "confirm",
  [AcnFormStep.CHARGE]: "charge",
  [AcnFormStep.COMPLETE]: "complete",
};
