import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import type { IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum DirectorQuestion {
  Name = "DIRECTOR_NAME",
  Birthday = "DIRECTOR_BIRTHDAY",
  Nationality = "DIRECTOR_NATIONALITY",
  Address = "DIRECTOR_ADDRESS",
  PreviousAddress = "DIRECTOR_PREVIOUS_ADDRESS",
  Attributes = "DIRECTOR_ATTRIBUTES",
  Check = "DIRECTOR_CHECK",
  CancellationCover = "DIRECTOR_CANCELLATION_COVER",
  PayeRegistration = "DIRECTOR_PAYE_REGISTRATION",
}

export const ISSUE_FIELD_TO_QUESTION: IssueFieldQuestionMap<DirectorQuestion> = {
  dob: DirectorQuestion.Birthday,
  first_name: DirectorQuestion.Name,
  middle_names: DirectorQuestion.Name,
  last_name: DirectorQuestion.Name,
  nationality: DirectorQuestion.Nationality,
  attributes: DirectorQuestion.Attributes,
  address: DirectorQuestion.Address,
  service_address: DirectorQuestion.Address,
  check: DirectorQuestion.Check,
};

export const QUESTION_TO_PRODUCT: { [key in DirectorQuestion]?: CompanyProduct } = {
  [DirectorQuestion.CancellationCover]: CompanyProduct.CancellationCover,
  [DirectorQuestion.PayeRegistration]: CompanyProduct.PayeRegistration,
};

export const QUESTION_TO_PATH = {
  [DirectorQuestion.Name]: "name",
  [DirectorQuestion.Birthday]: "birthday",
  [DirectorQuestion.Nationality]: "nationality",
  [DirectorQuestion.Address]: "address",
  [DirectorQuestion.PreviousAddress]: "previous-address",
  [DirectorQuestion.Attributes]: "attributes",
  [DirectorQuestion.Check]: "check",
  [DirectorQuestion.CancellationCover]: "cancellation-cover",
  [DirectorQuestion.PayeRegistration]: "paye-registration",
};
