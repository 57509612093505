import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  CompanyBusinessActivitiesData,
  type CompanyBusinessActivityCreateData,
  type CompanyBusinessActivityData,
  type CompanyBusinessActivityDeleteData,
} from "./companyBusinessActivitiesTypes";

export class CompanyBusinessActivitiesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getActivities(companyId: string): Promise<CompanyBusinessActivityData[]> {
    const response = await this._apiClient.get(`api/formations/companies/${companyId}/business-activity`);

    return response.getData(CompanyBusinessActivitiesData).businessActivities;
  }

  async createActivity(
    companyId: string,
    data: CompanyBusinessActivityCreateData,
  ): Promise<CompanyBusinessActivityData[]> {
    const response = await this._apiClient.post(`api/formations/companies/${companyId}/business-activity`, data);

    return response.getData(CompanyBusinessActivitiesData).businessActivities;
  }

  async deleteActivity(
    companyId: string,
    data: CompanyBusinessActivityDeleteData,
  ): Promise<CompanyBusinessActivityData[]> {
    const response = await this._apiClient.delete(`api/formations/companies/${companyId}/business-activity`, data);

    return response.getData(CompanyBusinessActivitiesData).businessActivities;
  }
}
