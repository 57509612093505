import { makeAutoObservable } from "mobx";
import { type CompanyProductsClient } from "services/companyProducts/companyProductsClient";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { type Datetime } from "types/date";

export class FormationExpressStore {
  private _featureAvailable: boolean = false;
  private _currectTimestamp?: Datetime;
  private _availableUntil?: Datetime;
  private _interval?: number;

  constructor(
    private readonly _companyProductsClient: CompanyProductsClient,
    private readonly _companyProductsStore: CompanyProductsStore,
  ) {
    makeAutoObservable(this);
  }

  get availableUntil(): Datetime | undefined {
    return this._availableUntil;
  }

  private set availableUntil(value: Datetime | undefined) {
    this._availableUntil = value;
  }

  get currectTimestamp(): Datetime | undefined {
    return this._currectTimestamp;
  }

  private set currectTimestamp(value: Datetime | undefined) {
    this._currectTimestamp = value;
  }

  get featureAvailable(): boolean {
    return this._featureAvailable;
  }

  private set featureAvailable(value: boolean) {
    this._featureAvailable = value;
  }

  get minutesLeft(): number {
    if (!this.availableUntil || !this.currectTimestamp) {
      return 0;
    }

    return this.availableUntil.diff(this.currectTimestamp, "minutes");
  }

  get needToShow(): boolean {
    if (this.featureAvailable) {
      return true;
    }

    return this._companyProductsStore.hasProduct(CompanyProduct.SameDayRegistration);
  }

  async init(): Promise<void> {
    await this._loadState();

    this._clearInterval();

    this._invokeInterval();
  }

  async refresh(): Promise<void> {
    await this._loadState();

    this._clearInterval();

    this._invokeInterval();
  }

  private async _loadState(): Promise<void> {
    const state = await this._companyProductsClient.getFormationExpressState();
    this.featureAvailable = state.featureAvailable;
    this.currectTimestamp = state.currectTimestamp ?? undefined;
    this.availableUntil = state.availableUntil ?? undefined;
  }

  private _invokeInterval(): void {
    const { featureAvailable, currectTimestamp, availableUntil } = this;

    if (!featureAvailable || !currectTimestamp || !availableUntil) {
      return;
    }

    this._interval = window.setInterval(() => {
      this.currectTimestamp = this.currectTimestamp?.add(1, "minute");

      if (
        this.currectTimestamp?.isSame(availableUntil, "minutes") ||
        this.currectTimestamp?.isAfter(availableUntil, "minutes")
      ) {
        this._clearInterval();
        this.featureAvailable = false;
      }
    }, 60000);
  }

  private _clearInterval(): void {
    window.clearInterval(this._interval);
  }
}
