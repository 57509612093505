import { makeAutoObservable, runInAction } from "mobx";
import { type NameCheckerClient } from "./nameCheckerClient";

export type NameCheckerState = "pending" | "success" | "warning" | "error";

export class NameCheckerStore {
  private _state: NameCheckerState = "pending";
  private _lastCheckedName?: string;

  constructor(private readonly _nameCheckerClient: NameCheckerClient) {
    makeAutoObservable(this);
  }

  get state(): NameCheckerState {
    return this._state;
  }

  set state(value: NameCheckerState) {
    this._state = value;
  }

  checkNameAvailability = async (name: string): Promise<void> => {
    try {
      if (!name || name === this._lastCheckedName) {
        return;
      }

      runInAction(() => {
        this.state = "pending";
      });

      if (!name) {
        return;
      }

      runInAction(() => {
        this._lastCheckedName = name;
      });

      const { isAvailable } = await this._nameCheckerClient.checkNameAvailability(name);

      runInAction(() => {
        this.state = isAvailable ? "success" : "warning";
      });
    } catch (error) {
      this.state = "error";
    }
  };
}
