import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum PersonalAttributeType {
  BirthTown = "BIRTH_TOWN",
  Telephone = "TELEPHONE",
  NationalInsuranceNumber = "NATIONAL_INSURANCE_NUMBER",
  PassportNumber = "PASSPORT_NUMBER",
  MotherMaidenName = "MOTHER_MAIDEN_NAME",
  FatherForename = "FATHER_FORENAME",
}

@Serializable()
export class PersonalAttributeData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly directorId: string,
    @JsonProperty({ type: PersonalAttributeType })
    readonly type: PersonalAttributeType,
    @JsonProperty() readonly value: string,
  ) {}
}

@Serializable()
export class PersonalAttributeListData {
  constructor(
    @JsonProperty({ type: PersonalAttributeData, isArray: true })
    readonly attributes: PersonalAttributeData[],
  ) {}
}

@Serializable()
export class PersonalAttributeCreateData {
  constructor(
    @JsonProperty({ type: PersonalAttributeType })
    readonly type: PersonalAttributeType,
    @JsonProperty() readonly value: string,
  ) {}
}

@Serializable()
export class PersonalAttributeListCreateData {
  constructor(
    @JsonProperty() readonly directorId: string,
    @JsonProperty({ type: PersonalAttributeCreateData, isArray: true })
    readonly attributes: PersonalAttributeCreateData[],
  ) {}
}
