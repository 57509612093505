import { AnnaError } from "@anna-money/anna-web-lib";

import { makeAutoObservable } from "mobx";

import { Config } from "config/config";
import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import type { AddressFormValue } from "helpers/forms/addressTypes";
import { directorFormToDirectorUpdateData } from "helpers/forms/director";
import type { DirectorStepFormValues } from "helpers/forms/directorTypes";
import type { CompanyStore } from "services/company/companyStore";
import { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import {
  DirectorCreateData,
  DirectorData,
  DirectorSharesUpdateData,
  DirectorUpdateData,
  MainDirectorCreateData,
} from "services/director/directorTypes";
import { FormError } from "types/errors";
import { type FormSubmitResult } from "types/form";
import { DirectorAddressKey, DirectorAddressStore } from "./directorAddressStore";
import { DirectorAttributesStore } from "./directorAttributesStore";
import { DirectorClient } from "./directorClient";

export interface DirectorState {
  directors: DirectorData[];
  mainDirector?: DirectorData;
}

export enum DirectorEntity {
  Address = "Address",
  PreviousAddress = "PreviousAddress",
  Attributes = "Attributes",
  Check = "Check",
  PayeRegistration = "PayeRegistration",
  CancellationCover = "CancellationCover",
}

export class NoMainDirectorError extends AnnaError {
  constructor(message: string = "No main director in the store") {
    super(message);
  }
}

export class DirectorStore {
  private readonly _state: DirectorState = { directors: [] };

  constructor(
    private readonly _directorClient: DirectorClient,
    private readonly _companyStore: CompanyStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _attributesStore: DirectorAttributesStore,
    private readonly _addressStore: DirectorAddressStore,
    private readonly _config: Config,
  ) {
    makeAutoObservable(this);
  }

  get directors(): DirectorData[] {
    return this._state.directors;
  }

  private set directors(value: DirectorData[]) {
    this._state.directors = value;
  }

  get mainDirector(): DirectorData | undefined {
    return this._state.mainDirector;
  }

  private set mainDirector(value: DirectorData | undefined) {
    this._state.mainDirector = value;
  }

  async init(): Promise<void> {
    await this._getDirectorList();

    for (const director of this.directors) {
      await this._addressStore.loadForDirector(director.id);
      if (!this._config.isAU) {
        await this._addressStore.loadForDirector(director.id, DirectorAddressKey.Previous);
        await this._attributesStore.loadForDirector(director.id);
      }
    }
  }

  getMainDirector(): DirectorData {
    if (!this.mainDirector) {
      throw new NoMainDirectorError();
    }

    return this.mainDirector;
  }

  tryGetDirectorById(id: string): DirectorData | undefined {
    return this._state.directors.find((director) => director.id === id);
  }

  getDirectorById(id: string): DirectorData {
    const director = this.tryGetDirectorById(id);

    if (!director) {
      throw new AnnaError(`No director with the id ${id}`);
    }

    return director;
  }

  // method for update only main director during passing through wizard
  async updateMainDirector(formValues: DirectorStepFormValues, entity: DirectorEntity): Promise<void> {
    if (!this.mainDirector) {
      await this._createMainDirector();
    }

    switch (entity) {
      case DirectorEntity.Address: {
        const mainDirector = this.getMainDirector();
        await this._updateAddress(mainDirector.id, formValues.address);
        this._addressStore.setPreviousAddressRequired(mainDirector.id, Boolean(formValues.previousAddressRequired));
        break;
      }
      case DirectorEntity.PreviousAddress: {
        const mainDirector = this.getMainDirector();
        await this._updateAddress(mainDirector.id, formValues.previousAddress, DirectorAddressKey.Previous);
        break;
      }
      case DirectorEntity.Attributes: {
        const mainDirector = this.getMainDirector();
        await this._updateAttributes(mainDirector.id, formValues);
        break;
      }
      case DirectorEntity.Check:
        break;
      case DirectorEntity.PayeRegistration:
        await this._updatePayeRegistration(formValues);
        break;
      case DirectorEntity.CancellationCover:
        await this._updateCancellationCover(formValues);
        break;
      default:
        await this._updateMainDirector(formValues);
    }
  }

  @FormSubmitMethod()
  async updateAddress(directorId: string, address?: AddressFormValue): Promise<FormSubmitResult> {
    await this._updateAddress(directorId, address);
  }

  async createDirector(data: DirectorCreateData): Promise<string> {
    const directorData = await this._directorClient.createDirector(data);

    await this._getDirectorList();

    return directorData.id;
  }

  async updateDirector(directorId: string, data: DirectorUpdateData): Promise<void> {
    await this._directorClient.updateDirector(directorId, data);

    await this._getDirectorList();
  }

  async deleteDirector(directorId: string): Promise<void> {
    await this._directorClient.deleteDirector(directorId);

    await this._getDirectorList();
  }

  async updateShares(directorId: string, shares?: number): Promise<void> {
    await this._directorClient.updateDirector(directorId, new DirectorSharesUpdateData(shares ?? null));

    await this._getDirectorList();
  }

  private async _getDirectorList(): Promise<void> {
    const { company } = this._companyStore;

    if (!company) {
      return;
    }

    this.directors = await this._directorClient.getDirectorList();

    for (const director of this.directors) {
      if (director.id === company.registeredWithDirectorId) {
        this.mainDirector = director;
      }
    }
  }

  private async _updateCancellationCover(formValues: DirectorStepFormValues): Promise<void> {
    formValues.cancellationCover
      ? await this._companyProductsStore.addProduct(CompanyProduct.CancellationCover)
      : await this._companyProductsStore.deleteProduct(CompanyProduct.CancellationCover);
  }

  private async _updatePayeRegistration(formValues: DirectorStepFormValues): Promise<void> {
    formValues.payeRegistration
      ? await this._companyProductsStore.addProduct(CompanyProduct.PayeRegistration)
      : await this._companyProductsStore.deleteProduct(CompanyProduct.PayeRegistration);
  }

  private async _createMainDirector(): Promise<void> {
    this.mainDirector = await this._directorClient.createDirector(
      new MainDirectorCreateData(this._companyStore.getCompany().id),
    );
  }

  private async _updateMainDirector(formValues: DirectorStepFormValues): Promise<void> {
    this.mainDirector = await this._directorClient.updateDirector(
      this.getMainDirector().id,
      directorFormToDirectorUpdateData(formValues),
    );
  }

  private async _updateAddress(
    directorId: string,
    address?: AddressFormValue,
    addressKey?: DirectorAddressKey,
  ): Promise<void> {
    if (!address) {
      throw new FormError("Something went wrong, no address in the form");
    }

    await this._addressStore.updateForDirector(directorId, address, addressKey);

    await this._getDirectorList();
  }

  private async _updateAttributes(directorId: string, formValues: DirectorStepFormValues): Promise<void> {
    if (!formValues.attributes) {
      throw new FormError("Something went wrong, no attributes in the form");
    }

    await this._attributesStore.updateForDirector(directorId, formValues.attributes);
  }
}
