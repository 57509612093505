import { formatDateYYYYMMDD } from "helpers/date";
import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import type { CompanyStore } from "services/company/companyStore";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export interface AbnStartDateFormValues {
  startDate?: string;
}

export class AbnStartDateFormStore {
  constructor(private readonly _companyStore: CompanyStore) {}

  @FormSubmitMethod()
  async onSubmit(values: AbnStartDateFormValues): Promise<FormSubmitResult> {
    if (!values.startDate) {
      throw new FormError("No company name or name postfix selected");
    }

    await this._companyStore.updateAbnStartDate(formatDateYYYYMMDD(values.startDate));
  }
}
