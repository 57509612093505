import classNames from "classnames";
import { type FC, type ReactNode } from "react";

import { Text, type TextProps } from "components/Text/Text";
import styles from "./Paragraph.module.scss";

interface ParagpaphProps {
  children: ReactNode;
  weight?: "regular" | "semibold";
  size?: TextProps["size"];
  className?: string;
}

export const Paragraph: FC<ParagpaphProps> = ({ children, weight = "regular", size = "m", className }) => {
  return (
    <Text className={classNames(styles.paragraph, className)} size={size} weight={weight} tag="p">
      {children}
    </Text>
  );
};
