import { makeAutoObservable } from "mobx";

import { type NationalitiesClient } from "./nationalitiesClient";
import { type NationalitiesData } from "./nationalitiesTypes";

export class NationalitiesStore {
  private readonly _nationalitiesClient: NationalitiesClient;

  private _state?: NationalitiesData;

  constructor(nationalitiesClient: NationalitiesClient) {
    this._nationalitiesClient = nationalitiesClient;

    makeAutoObservable(this);
  }

  async getNationalities(): Promise<NationalitiesData> {
    if (!this._state) {
      this._state = await this._nationalitiesClient.getNationalities();
    }

    return this._state;
  }
}
