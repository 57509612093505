import { type ApiClient } from "@anna-money/anna-web-lib";
import { type LeadCreateData } from "./leadTypes";

export class LeadClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async saveLead(data: LeadCreateData): Promise<void> {
    await this._apiClient.post("api/formations/contact", data);
  }
}
