import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { Config } from "config/config";
import { DataStorage } from "services/storage/dataStorage";
import { AuthClient } from "./authClient";
import { UnauthenticatedError } from "./errors";

const accessTokenKey = "access_token";
const accessTokenRefreshed = "access_token_refreshed";

@Serializable()
export class AccessTokenPayload {
  constructor(@JsonProperty() readonly data: string) {}
}

@Serializable()
export class AccessTokenRefreshedPayload {
  constructor(@JsonProperty({ type: Boolean }) readonly isRefreshed: boolean) {}
}

export class Authenticator {
  private readonly _config: Config;
  private readonly _authClient: AuthClient;
  private readonly _dataStorage: DataStorage;

  constructor(config: Config, authClient: AuthClient, dataStorage: DataStorage) {
    this._config = config;
    this._authClient = authClient;
    this._dataStorage = dataStorage;
  }

  get isAuthenticated(): boolean {
    return !!this._loadAccessToken();
  }

  get isTokenRefreshed(): boolean {
    return Boolean(this._dataStorage.get(accessTokenRefreshed, AccessTokenRefreshedPayload)?.isRefreshed);
  }

  get accessToken(): string {
    const accessToken = this._loadAccessToken();
    if (!accessToken) {
      throw new UnauthenticatedError();
    }
    return accessToken.data;
  }

  getAuthUrl(callbackUrl: URL, loginHint?: string, screenType?: string): string {
    const params = new URLSearchParams({
      client_id: this._config.annaAuthClientId,
      redirect_uri: callbackUrl.toString(),
      screen: screenType === "login" ? "login" : "signup",
    });
    if (loginHint) {
      params.append("login_hint", loginHint);
    }
    return `${this._config.annaAuthUrl}/authorize?${params.toString()}`;
  }

  getLogoutRedirectUrl(): string {
    return "/";
  }

  async authenticate(currentUrl: URL, callbackUrl: URL): Promise<boolean> {
    const authCode = currentUrl.searchParams.get("code");
    if (!authCode) {
      return false;
    }

    const accessToken = await this._authClient.getAccessToken(
      this._config.annaAuthClientId,
      authCode,
      callbackUrl.toString(),
    );
    this._saveAccessToken(new AccessTokenPayload(accessToken));
    return true;
  }

  reset(): void {
    this._dataStorage.remove(accessTokenKey);
  }

  markTokenRefreshed(): void {
    this._dataStorage.set(accessTokenRefreshed, new AccessTokenRefreshedPayload(true));
  }

  async logout(): Promise<void> {
    this._dataStorage.remove(accessTokenKey);
    this._dataStorage.remove(accessTokenRefreshed);
    await this._authClient.logout();
  }

  private _saveAccessToken(accessToken: AccessTokenPayload): void {
    this._dataStorage.set(accessTokenKey, accessToken);
  }

  private _loadAccessToken(): AccessTokenPayload | null {
    return this._dataStorage.get(accessTokenKey, AccessTokenPayload);
  }
}
