import { ensured } from "helpers/value";
import { type AddressVerificationClient } from "services/addressVerification/addressVerificationClient";
import type { CompanyStore } from "services/company/companyStore";
import type { DirectorAddressStore } from "services/director/directorAddressStore";
import type { DirectorStore } from "services/director/directorStore";

export class AddressVerificationStore {
  private _state: Record<string, boolean | undefined> = {};

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _directorStore: DirectorStore,
    private readonly _addressStore: DirectorAddressStore,
    private readonly _addressVerificationClient: AddressVerificationClient,
  ) {}

  get isPoaRequired(): boolean {
    if (this._companyStore.getCompany().poaFileId) {
      return false;
    }

    const isPoaRequired = this._state[this._getAddressId()];
    return typeof isPoaRequired === "undefined" ? true : isPoaRequired;
  }

  async checkDirectorAddress(): Promise<boolean> {
    const addressId = this._getAddressId();
    const isPoaRequired = await this._getPoaRequirementForAddress(addressId);
    this._state[addressId] = isPoaRequired;

    return isPoaRequired;
  }

  private _getAddressId(): string {
    const address = ensured(this._addressStore.tryGetForDirector(this._directorStore.getMainDirector().id));
    return address.id;
  }

  private async _getPoaRequirementForAddress(addressId: string): Promise<boolean> {
    try {
      const result = await this._addressVerificationClient.getAddressVerification(addressId);
      return result.needPoa;
    } catch (e) {
      console.error(`Couldn't load verification results for address ${addressId}`);
      return true;
    }
  }
}
