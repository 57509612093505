import { type ApiClient, createSearchParams } from "@anna-money/anna-web-lib";

import {
  AddressAutocompleteResponseData,
  type ResolveAutocompleteData,
  ResolveAutocompleteResponseData,
} from "services/addressSearch/addressSearchTypes";

export class AddressSearchClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getAddressSuggestions(query: string): Promise<AddressAutocompleteResponseData> {
    const response = await this._apiClient.get(
      `api/formations/addresses/search?${createSearchParams({ autocomplete_query: query }).toString()}`,
    );

    return response.getData(AddressAutocompleteResponseData);
  }

  async getFullAddress(data: ResolveAutocompleteData): Promise<ResolveAutocompleteResponseData> {
    const response = await this._apiClient.post("api/formations/addresses/resolve-autocomplete", data);

    return response.getData(ResolveAutocompleteResponseData);
  }
}
