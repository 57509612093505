import type { AddressVerificationStore } from "services/addressVerification/addressVerificationStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { IdentityQuestion } from "services/questions/au/identityConstants";
import { QuestionsStore } from "services/questions/questionsStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";

export class IdentityQuestionsStore extends QuestionsStore<IdentityQuestion> {
  constructor(
    private readonly _addressVerificationStore: AddressVerificationStore,
    private readonly _sumsubStore: SumsubApplicationStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(IdentityQuestion, _issuesStore);
  }

  protected _shouldSkipQuestion(question: IdentityQuestion): boolean {
    switch (question) {
      case IdentityQuestion.Poa:
        return !this._addressVerificationStore.isPoaRequired;
    }

    return super._shouldSkipQuestion(question);
  }

  protected _getPreviousQuestionKey(): IdentityQuestion | undefined {
    const prevQuestionKey = super._getPreviousQuestionKey();

    switch (prevQuestionKey) {
      case IdentityQuestion.IdentityCompliance:
        return IdentityQuestion.Identity;
    }

    return prevQuestionKey;
  }

  protected _getRelevantQuestion(): IdentityQuestion | undefined {
    if (this._sumsubStore.isPassedVerifications) {
      return IdentityQuestion.IdentityCompliance;
    }

    return this.firstQuestion;
  }
}
