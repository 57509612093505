import { AnnaError, type ApiClient } from "@anna-money/anna-web-lib";

import { CheckoutResponseData } from "./billingTypes";

export class BillingClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async charge(): Promise<CheckoutResponseData> {
    try {
      const response = await this._apiClient.post("api/formations/billing/charge", {});

      return response.getData(CheckoutResponseData);
    } catch (error) {
      console.error(new AnnaError("Failed to charge customer", { cause: error }));

      return new CheckoutResponseData(false);
    }
  }
}
