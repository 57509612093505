import type { FC } from "react";
import { Link } from "components/Link/Link";
import { Subtitle } from "components/Subtitle/Subtitle";
import styles from "./Terms.module.scss";

const TERMS = [
  {
    title: "Terms & conditions",
    // eslint-disable-next-line max-len
    text: "Your use of the Website is subject to these terms and conditions, and any additional or specific terms we may draw to your attention.",
  },
  {
    title: "1. Definitions",
    // eslint-disable-next-line max-len
    text: 'The following definitions apply <br> <ol><li>"Company Registration Terms and Conditions" means the terms and conditions specific to any company registration applications made through the Website which are listed in clauses 11-15 below.</li><li>"Customer" shall mean the person that is using this website or using the company registration services.</li><li>"Terms and Conditions" means the Company Registration Terms and Conditions and the Website Terms and Conditions.</li><li>"we" or "us" or "our" or "ourselves" refers to Register Your Company, which is a brand of Absolutely No Nonsense Admin Ltd, a company registered in England and Wales with number 10149389 and whose registered office is at Brunel House, 2 Fitzalan Road, Cardiff, Wales, CF24 0EB.</li><li>"Website" "Web site" or "Site" means the website you are browsing when you clicked on a link to these Terms and Conditions, including all subsidiary pages.</li><li>"Website Terms and Conditions" means the terms and conditions related to use of the website generally which are listed in clauses 2-10 below.</li></ol><br>Website Terms and Conditions',
  },
  {
    title: "2. Updates and Changes",
    text: "We amend these Terms and Conditions from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated on 21 March 2022.",
  },
  {
    title: "3. Exclusion of liability",
    text: "<ol><li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</li><li>Subject to (i) above:<ul><li>we exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it;</li><li>we will not be liable to you for any loss or damage, whether in contract, tort, breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: (1) use of, or inability to use, our site; or (2) use of or reliance on any content displayed on our site;</li><li>no warranty is given that the functionality of the Website will be uninterrupted or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or destructive; and</li><li>we are not responsible for the content of other websites that link to this Website, nor are we responsible for the content of any website to which links are provided from this Website. Links to other sites are provided purely for your convenience and do not imply that we approve of those sites</li></ul></li></ol>",
  },
  {
    title: "4. Intellectual Property",
    text:
      // eslint-disable-next-line max-len
      "All intellectual property rights in all materials on the Website, including their design, layout, text, graphics, source code, software, and trademarks are the property of their respective owners.\n" +
      "\n" +
      "You may view and temporarily store Website pages and their content in your browser’s temporary cache. You may also print out a single copy for non-commercial purposes and off-line review for reference. You may not sell or re-sell anything available from the Website.",
  },
  {
    title: "5. Account",
    text: "This website requires registration in order to gain enhanced access privileges or in order to monitor your company registration. If you register, it is your responsibility to maintain the confidentiality of your password. should You should not disclose your password to anyone else. You agree to indemnify and hold us harmless for any loss or damage we may incur resulting from breach of this clause.",
  },
  {
    title: "6. Data Protection",
    text: "We will only use your personal information as set out in our privacy policy which you can assess here https://anna.money/privacy-policy.",
  },
  {
    title: "7. Cookies",
    text: "Our Sites use certain cookies, pixels, beacons, log files and other technologies of which you should be aware. Please see our Cookie Policy at https://anna.money/cookie-policy to find out more about the cookies we use and how to manage and delete cookies.",
  },
  {
    title: "8. Website Availability",
    text:
      // eslint-disable-next-line max-len
      "Our site is made available free of charge, subject to the restrictions set out in these Terms and Conditions.  \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend, withdraw, or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.\n" +
      "\n" +
      // eslint-disable-next-line max-len
      "You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.",
  },
  {
    title: "9. General",
    text:
      // eslint-disable-next-line max-len
      "If any provision of these Terms and Conditions is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and the validity and enforceability of the remaining provisions shall not be affected. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "No person who is not a direct party to any agreement covered by these Terms and Conditions shall have any right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Agreement. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "Even if we delay or fail to exercise any right or remedy under these Terms and Conditions, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking these Terms and Conditions that will not mean that you do not have to do those things, nor will it prevent us taking steps against you at a later date. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "You will not assign or transfer any of your rights and benefits under these Terms and Conditions. We may at any time assign, transfer or subcontract any or all of our rights and benefits under these Terms and Conditions without prior written notice to you. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "We may assign transfer or subcontract any or all of our obligations herein to any group company, affiliate or selected third party: (a) by giving 15 calendar days’ prior notice in writing or (b) at any time (with or without notice) to comply with any law or regulation. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "No third party who is not a party to these Terms and Conditions has a right to enforce any of the terms in them. \n" +
      "\n" +
      // eslint-disable-next-line max-len
      "All the information we give you and all communications between you and us will be in English. All notices from us to you will be to the email address you give when registering with us or as otherwise later amended. You may contact us at formations@anna.money",
  },
  {
    title: "10. Governing law and Jurisdiction",
    text: "Your use of the Website and company registration service are governed in accordance with the laws of England and Wales. The English courts shall have exclusive jurisdiction over any dispute or difference whatsoever arising out of or in connection with your use of the Website or the purchase of any products or services from it.",
  },
  {
    title: "11. Ways to use this service",
    // eslint-disable-next-line max-len
    text: "We provide a service under which we collect information about a company you want to form, and pass that information to Companies House, using a technological integration that we have built to interface directly with Companies House. As such, we act as a means of allowing you to pass information to Companies House in a convenient way, but do not act as an advisor in how to create a company. <br><br> You may use this service: <br> <ol><li>To register no more than one limited company with one shareholder (you can add additional shareholders and directors at a later date by contacting Companies House directly);</li><li>For your own business only, not to be used on behalf of anyone else;</li></ol><br>You may not use this service to create shell companies unless they are intended to be used immediately for the purposes of your own business.<br><br>You are responsible for dealing with any subsequent filings or governance of the company (ex. annual returns, keeping a register of PSCs (people with significant control), etc.)<br><br>We provide this service free of charge, subject to the restrictions set out above. If and to the extent that you use the service outside these restrictions without our express written permission, we reserve the right to terminate your use of the service, and to charge you at a rate of £100 per additional company formed.",
  },
  {
    title: "12. What you receive",
    text: "Once we receive electronic confirmation of incorporation from Companies House, we will automatically send you a confirmation email with digital copies of:<br><br><ol><li>Certificate of Incorporation</li><li>Automatically generated Memorandum of Association</li><li>Automatically generated Articles of Association based on the Model Articles of Association;</li><li>Automatically generated Share Certificates / Statement of Capital</li></ol><br>However, we are simply passing on the documents that we receive from Companies House, and it is your responsibility to check that they contain the correct information.",
  },
  {
    title: "13. Limitation of liability",
    // eslint-disable-next-line max-len
    text: "<ol><li>We do not accept any liability for any errors or omissions in the company formation information that you submit through our Website, or for any such company formation application which is subsequently rejected by Companies House.</li><li>We are not responsible or liable for any rejection of incorporation or problems arising due to the appointment not meeting the legal requirements for company formation.</li><li>Where a company name is selected by the Customer for registration for whatever reason and if that registration is permitted, it is permitted by the registrar on the basis of the registrar’s view that it will not conflict with the name of any other company at that time of registration.</li><li>We do not warrant that the use of the company name will not conflict with the rights of currently operating businesses, and in particular we do not warrant that the use of the name may not give rise to actions for passing off, or for infringement of any other proprietary or legal right. We have not investigated and cannot investigate the possibility of the existence of conflicting rights and the Customer accepts sole responsibility for meeting all and any claims of any kind whatsoever arising out of the use of the company name and agrees to indemnify us in respect of any costs, expenses or damages it suffers or for which it is held liable as a result of any such claims.</li><li>For the avoidance of doubt, the liability restrictions in clause 4 apply to the company registration service in the same way that they apply to the provision of the website itself, in addition to the terms in this clause 13.</li></ol>",
  },
  {
    title: "14. Timeframe for Incorporation",
    text: "Provided you have complied with the formalities necessary to purchase a company from us, the Registrar of Companies will generally complete the incorporation within 24 hours during normal working hours. However, we have no control over this process, which may take longer as incorporation is always dependant on Companies House workload and availability.",
  },
  {
    title: "15. Services and products we do not provide",
    // eslint-disable-next-line max-len
    text: "We do not include any of the following: <br> <ol><li>Accountancy services;</li><li>Tax advice;</li><li>Auditing of your books;</li><li>Company secretarial services</li><li>Legal advice;</li><li>Filing of annual accounts</li><li>Company search reports;</li><li>Sale of shelf-companies; nor</li><li>Any other products or services not expressly mentioned.</li></ol>",
  },
];

export const Terms: FC = () => {
  return (
    <div className={styles.terms}>
      {TERMS.map(({ title, text }) => (
        <div key={title} className={styles.block}>
          <Subtitle className={styles.title}>{title}</Subtitle>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ))}
      <div className={styles.button}>
        <Link href="/">Back</Link>
      </div>
    </div>
  );
};
