export enum AbnQuestion {
  StartDate = "ABN_START_DATE",
  BusinessActivity = "ABN_BUSINESS_ACTIVITY",
  AgriculturalOperations = "ABN_AGRICULTURAL_OPERATIONS",
}

export const ABN_FIELD_TO_PATH: Record<AbnQuestion, string> = {
  [AbnQuestion.StartDate]: "start-date",
  [AbnQuestion.BusinessActivity]: "business-activity",
  [AbnQuestion.AgriculturalOperations]: "agricultural-operations",
};
