import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import { type CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { CompanyAddressStoreBase } from "services/companyAddress/companyAddressStoreBase";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export type CompanyAddressFormValues = Pick<
  CompanyFormValues,
  "useVirtualOffice" | "virtualOfficeBillingPeriod" | "address"
>;

export abstract class CompanyAddressFormStoreBase {
  protected constructor(protected readonly _companyAddressStore: CompanyAddressStoreBase) {}

  @FormSubmitMethod()
  async onSubmit(values: CompanyAddressFormValues): Promise<FormSubmitResult> {
    if (values.useVirtualOffice && values.virtualOfficeBillingPeriod) {
      await this._companyAddressStore.updateAddress({
        virtualOfficeBillingPeriod: values.virtualOfficeBillingPeriod,
      });
      return;
    }

    if (values.address) {
      await this._companyAddressStore.updateAddress({ address: values.address });
      return;
    }

    throw new FormError("No virtual office and no address selected");
  }
}
