import { Button } from "@anna-money/anna-web-ui";

import type { FC, ReactNode } from "react";
import { Subtitle } from "components/Subtitle/Subtitle";
import styles from "./WhyVirtualOffice.module.scss";

type PageTitle = string;
type PageText = string;
type PageTexts = Array<PageText | PageItem>;
type PageList = PageItem[];
type PageItem = {
  title?: PageTitle;
  text?: PageText;
  texts?: PageTexts;
  list?: PageList;
};

const Page: PageItem[] = [
  {
    title: "Getting a virtual office",
    texts: [
      "You can legally register your company to a virtual office address. You can also use a virtual office as your " +
        "service address. These two addresses appear publicly on Companies House, and can be accessed by anyone.",
      "A virtual office isn't a physical place that you can visit and work from, but you can register your business " +
        "to a virtual office address, and also use it for all your business correspondence. Most importantly, " +
        "it allows your personal address to remain private. That way your personal address won't appear on the public " +
        "register, and you can add extra credibility to your contracts, website etc by using a virtual office address " +
        "instead of your personal one. Your virtual office address can also be used for all legal matters " +
        "and correspondence.",
    ],
  },
  {
    title: "Our virtual offices are supplied by Hoxton Mix",
    texts: [
      "Hoxton Mix is based in Shoreditch, London and their virtual office service is ideal for entrepreneurs, " +
        "freelancers and startups looking for a prime business address in the City of London. Get a desirable address " +
        "without renting a physical office space, and improve your image in front of potential clients.",
      'Find out more on their <a target="_blank" rel="noreferrer" href="https://hoxtonmix.com">website</a>.',
    ],
  },
  {
    title: "What if someone sends mail to my virtual office address?",
    texts: [
      "No problem. Your virtual office offers a same day mail handling and forwarding service. They'll also scan " +
        "your official government documents and send them to you via email, giving you digital copies of all important " +
        "business information. You can get your mail wherever you are — whether that's at home in the UK or abroad " +
        "on business — and your business address will remain the same regardless.",
      "Official written communications will be scanned from the following organisations:",
      {
        list: [
          { text: "Companies House" },
          { text: "HM Revenue & Customs (HMRC)" },
          { text: "Government Gateway" },
          { text: "Inland Revenue" },
          { text: "Information Commissioner's Office (ICO)" },
          { text: "Court documents (including Tribunals Services)" },
          { text: "Department of Work & Pensions (DWP)" },
          { text: "Intellectual Property Office (IPO)" },
          { text: "Office for National Statistics" },
        ],
      },
      "Any mail received that doesn't come from an official governmental body, or that cannot be scanned " +
        "(like credit cards, parcels etc) can be forwarded on for an additional cost upon request. " +
        "Any mail you don't want forwarded on will be destroyed after 21 days. ",
    ],
  },
  {
    title: "How much does it cost?",
    texts: [
      "Our virtual office service is £7.99 per month, and can be cancelled at any time.",
      "Just remember: in order to cancel your virtual office subscription, you must also change your address " +
        "on Companies House.",
    ],
  },
  {
    title: "What if I need to change my address?",
    texts: [
      "Don't worry — you can change your address at any time. As soon as you have your own business premesis, " +
        "you can cancel your virtual office. Just bear in mind that your previous monthly fees are non refundable, " +
        "and you must change your address on Companies House.",
    ],
  },
  {
    title: "",
    texts: [
      '<strong>ANNA Money company policies</strong><br /><a target="_blank" rel="noreferrer" href="https://anna.money/privacy-policy">Privacy policy</a><br /><a target="_blank" rel="noreferrer" href="https://anna.money/terms-and-conditions">Terms and Conditions</a>',
      '<strong>Hoxton Mix company policies</strong><br /><a target="_blank" rel="noreferrer" href="https://www.hoxtonmix.com/privacy-policy">Privacy policy</a><br /><a target="_blank" rel="noreferrer" href="https://www.hoxtonmix.com/terms-and-conditions">Terms and Conditions</a><br /><a target="_blank" rel="noreferrer" href="https://www.hoxtonmix.com/know-your-customer/">KYC requirements</a>',
    ],
  },
];

function renderTitle(title: PageTitle): ReactNode {
  return <Subtitle className={styles.title}>{title}</Subtitle>;
}

function renderText(text: PageText): ReactNode {
  return <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />;
}

function renderTexts(texts: PageTexts): ReactNode {
  return (
    <div className={styles.texts}>
      {texts.map((text) => (typeof text === "string" ? renderText(text) : renderItem(text)))}
    </div>
  );
}

function renderList(list: PageList): ReactNode {
  return (
    <ul className={styles.list}>
      {list.map((listItem, index) => (
        <li key={index} className={styles.item}>
          {renderItem(listItem)}
        </li>
      ))}
    </ul>
  );
}

function renderItem({ title, text, texts, list }: PageItem): ReactNode {
  return (
    <>
      {title && renderTitle(title)}
      {text && renderText(text)}
      {texts && renderTexts(texts)}
      {list && renderList(list)}
    </>
  );
}

export const WhyVirtualOffice: FC = () => {
  return (
    <div className={styles.page}>
      {Page.map((item, index) => (
        <div key={index} className={styles.block}>
          {renderItem(item)}
        </div>
      ))}

      <div className={styles.footerActions}>
        <Button size="m" href="/" text="Ok, got it" />
      </div>
    </div>
  );
};
