import { type ApiClient, createSearchParams } from "@anna-money/anna-web-lib";

import { NameAvailabilityData } from "./nameCheckerTypes";

export class NameCheckerClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  checkNameAvailability = async (name: string): Promise<NameAvailabilityData> => {
    const response = await this._apiClient.get(
      `api/formations/i/companies/check-name?${createSearchParams({ name }).toString()}`,
    );

    return response.getData(NameAvailabilityData);
  };
}
