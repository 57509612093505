import { FORM_ERROR } from "final-form";

export type UpdateFormResult = Promise<FormSubmitResult>;

export type UpdateFormCallback = () => UpdateFormResult;

export type FormSubmitError = string | Record<string, string | Record<string, string>>;

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type FinalFormSubmitResult = void | FormSubmitError;

export class FormValidationError extends Error {
  constructor(private _cause: FormSubmitError) {
    super("Form validation error");
  }

  get cause(): FormSubmitError {
    return this._cause;
  }

  toFinalFormError(): FinalFormSubmitResult {
    return typeof this.cause === "string" ? { [FORM_ERROR]: this.cause } : this.cause;
  }
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type FormSubmitResult = void | FormValidationError;
