import { makeAutoObservable } from "mobx";

import { type AddressVerificationStore } from "services/addressVerification/addressVerificationStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";
import type { LoadableState } from "types/loadableState";
import { type ComplianceClient } from "./complianceClient";
import { type ComplianceData } from "./complianceTypes";

type ComplianceStoreState = LoadableState<"success">;

export class ComplianceStore {
  private _state: ComplianceStoreState = "loading";

  constructor(
    private readonly _sumsubApplicationStore: SumsubApplicationStore,
    private readonly _complianceClient: ComplianceClient,
    private readonly _addressVerificationStore: AddressVerificationStore,
  ) {
    makeAutoObservable(this);
  }

  get state(): ComplianceStoreState {
    return this._state;
  }

  set state(value: ComplianceStoreState) {
    this._state = value;
  }

  async init(): Promise<void> {
    await this._defineState();

    if (this.state !== "loading") {
      return;
    }

    const interval = setInterval(async () => {
      await this._defineState();

      if (this.state !== "loading") {
        clearInterval(interval);
      }
    }, 3000);
  }

  async _defineState(): Promise<void> {
    const checkResult = await this._getComplianceCheck();

    if (!checkResult) {
      return;
    }

    this.state = checkResult.isSuccessful ? "success" : "error";
  }

  async _getComplianceCheck(): Promise<ComplianceData | undefined> {
    try {
      const checkResult = await this._complianceClient.getComplianceResult();

      if (!checkResult.isFinished) {
        return undefined;
      }

      // when compliance check is done then we have for sure the sumsub application and the address verification resuls
      await this._sumsubApplicationStore.getSumsubApplication();
      await this._addressVerificationStore.checkDirectorAddress();

      return checkResult;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (e?.data?.error?.code !== 1040) {
        throw e;
      }

      return undefined;
    }
  }
}
