import { type Constructor } from "@anna-money/anna-web-lib";
import { apiSerializer } from "helpers/serialization";
import { FallbackStorage } from "./fallbackStorage";

const annaPrefix = "anna_";

type BuiltInConstructor = StringConstructor | NumberConstructor | BooleanConstructor | ObjectConstructor;

export class DataStorage {
  _storage: FallbackStorage;

  constructor() {
    try {
      localStorage.getItem(annaPrefix);
      this._storage = localStorage;
    } catch (e) {
      this._storage = new FallbackStorage();
    }
  }

  private _getKey(key: string): string {
    return `${annaPrefix}${key}`;
  }

  get(key: string, type: StringConstructor): string | null;
  get(key: string, type: NumberConstructor): number | null;
  get(key: string, type: BooleanConstructor): boolean | null;
  get<T>(key: string, type: ObjectConstructor): T | null;
  get<T>(key: string, type: Constructor<T>): T | null;
  get<T>(key: string, type: Constructor<T> | BuiltInConstructor): string | number | boolean | T | null {
    const item = this._storage.getItem(this._getKey(key));

    if (item === null) {
      return null;
    }

    if (type === String) {
      return item;
    }

    if (type === Number || type === Boolean || type === Object) {
      return JSON.parse(item);
    }

    return apiSerializer.deserialize(JSON.parse(item), type as Constructor<T>);
  }

  set<T>(key: string, data: T): void {
    const item = apiSerializer.serialize(data);
    this._storage.setItem(this._getKey(key), typeof item === "string" ? item : JSON.stringify(item));
  }

  remove(key: string): void {
    this._storage.removeItem(this._getKey(key));
  }
}
