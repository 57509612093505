import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class SicCodeData {
  constructor(
    @JsonProperty() readonly code: string,
    @JsonProperty() readonly description: string,
  ) {}
}

@Serializable()
export class SicCodesData {
  constructor(@JsonProperty({ type: SicCodeData, isArray: true }) readonly sicCodes: SicCodeData[]) {}
}

@Serializable()
export class SicCodesRecommendData {
  constructor(@JsonProperty({ type: String, isArray: true }) readonly recommendedSicCodes: string[]) {}
}

@Serializable()
export class SicCodesRecommendRequestData {
  constructor(
    @JsonProperty() readonly businessDescription: string,
    @JsonProperty({ type: String, isArray: true }) readonly selectedSicCodes: string[],
  ) {}
}
