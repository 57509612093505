import { isGbPricingPlan } from "helpers/pricingPlan";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { type PricingPlanClient } from "services/pricingPlan/pricingPlanClient";
import { PricingPlanStoreBase } from "services/pricingPlan/pricingPlanStoreBase";
import type { PricingPlan, PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";

export class PricingPlanStore extends PricingPlanStoreBase<PricingPlanAu> {
  constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _companyProductsStore: CompanyProductsStore,
    protected readonly _pricingPlanClient: PricingPlanClient,
  ) {
    super(_companyStore, _companyProductsStore, _pricingPlanClient);
  }

  isSupportedPricingPlan(pricingPlan: PricingPlan): pricingPlan is PricingPlanAu {
    return !isGbPricingPlan(pricingPlan);
  }
}
