import { type ApiClient } from "@anna-money/anna-web-lib";
import { FeatureSet } from "./featureTypes";

export class FeatureClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getFeatures(timeout?: number): Promise<FeatureSet> {
    const response = await this._apiClient.get("/api/feature-toggle/v2/features", { timeout });
    return response.getData(FeatureSet);
  }
}
