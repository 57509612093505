const fallbackStorage: Record<string, string> = {};

export class FallbackStorage {
  getItem(key: string): string | null {
    return fallbackStorage[key] || null;
  }

  setItem(key: string, value: string): void {
    fallbackStorage[key] = value;
  }

  removeItem(key: string): void {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete fallbackStorage[key];
  }
}
