import { type ApiClient } from "@anna-money/anna-web-lib";
import { ComplianceData } from "./complianceTypes";

export class ComplianceClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getComplianceResult(): Promise<ComplianceData> {
    const response = await this._apiClient.get("api/formations/v2/compliance");
    return response.getData(ComplianceData);
  }
}
