import { makeAutoObservable } from "mobx";
import { type Authenticator } from "./authenticator";

type AuthStoreState = "unauthenticated" | "authenticating" | "authenticated";

export class AuthStore {
  private readonly _authenticator: Authenticator;

  constructor(authenticator: Authenticator) {
    makeAutoObservable(this);
    this._authenticator = authenticator;
  }

  private _state: AuthStoreState = "unauthenticated";

  get state(): AuthStoreState {
    return this._state;
  }

  private set state(state: AuthStoreState) {
    this._state = state;
  }

  get isTokenRefreshed(): boolean {
    return this._authenticator.isTokenRefreshed;
  }

  initialize(): void {
    this._state = this._authenticator.isAuthenticated ? "authenticated" : "unauthenticated";
  }

  getAuthUrl(callbackUrl: URL, loginHint?: string): string {
    return this._authenticator.getAuthUrl(callbackUrl, loginHint);
  }

  async authenticate(currentUrl: URL, callbackUrl: URL): Promise<void> {
    this.state = "authenticating";
    try {
      const authenticated = await this._authenticator.authenticate(currentUrl, callbackUrl);
      this.state = authenticated ? "authenticated" : "unauthenticated";
    } catch (e) {
      this.state = "unauthenticated";
      throw e;
    }
  }

  /**
   * In case we need to refresh token after company creation or smth important for security context
   */
  refresh(): void {
    this._authenticator.markTokenRefreshed();
    this.reset();
  }

  /**
   * Just the cleanup when the token is expired
   */
  reset(): void {
    this._authenticator.reset();
    this._state = "unauthenticated";
  }

  /**
   * When the customer explicitly wants to log out
   */
  async logout(onLogout?: (url: string) => void): Promise<void> {
    await this._authenticator.logout();
    if (onLogout) {
      onLogout(this._authenticator.getLogoutRedirectUrl());
    } else {
      this._state = "unauthenticated";
    }
  }
}
