import { type ApiClient } from "@anna-money/anna-web-lib";

import { type IssueData, IssueListData } from "./issuesTypes";

export class IssuesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getIssues(): Promise<IssueData[]> {
    const response = await this._apiClient.get("api/formations/company-issues");

    return response.getData(IssueListData).companyIssues;
  }

  async resolveIssue(id: string): Promise<void> {
    await this._apiClient.post(`api/formations/company-issues/${id}/resolve`, {});
  }
}
