import React, { type FC, useContext } from "react";
import { useFactory } from "./helpers/hooks/useFactory";
import { type ServicesAu } from "./servicesAu";
import { type ServicesGb } from "./servicesGb";

type AppServicesProps = { children: React.ReactNode; services: TServices };

export type TServices = ServicesGb | ServicesAu;

const AppServicesContext = React.createContext<TServices | null>(null);

export const AppServices: FC<AppServicesProps> = ({ children, services }) => {
  return <AppServicesContext.Provider value={services}>{children}</AppServicesContext.Provider>;
};

export function useServices<T extends TServices>(): T {
  const context = useContext(AppServicesContext);
  if (!context) {
    throw new Error("Services must be requested in AppServices context");
  }
  return context as T;
}

export function useServiceFactory<T extends TServices, V>(factory: (services: T) => V): V {
  const services = useServices<T>();
  return useFactory(() => factory(services));
}
