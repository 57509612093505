import { AnnaError } from "@anna-money/anna-web-lib";
import { formatMoney } from "helpers/money";
import type { BillingItemData } from "services/billingItems/types";
import { BillingItem } from "services/checkoutBasket/checkoutBasketTypes";
import { VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";

export class BillingItemsStore {
  getData(billingItem: BillingItem, param?: unknown): BillingItemData {
    switch (billingItem) {
      case BillingItem.VirtualOffice: {
        return this._getVirtualOfficeData(param as VirtualOfficeBillingPeriod);
      }
      default:
        throw new AnnaError("Unsupported billing item");
    }
  }

  private _getVirtualOfficeData(
    billingPeriod: VirtualOfficeBillingPeriod = VirtualOfficeBillingPeriod.Monthly,
  ): BillingItemData {
    const prices = {
      [VirtualOfficeBillingPeriod.Monthly]: {
        price: formatMoney(70, { monthly: true }),
        vat: formatMoney(7),
      },
      [VirtualOfficeBillingPeriod.Yearly]: {
        price: formatMoney(58, { monthly: true }),
        vat: formatMoney(5.8),
      },
    };
    const price = prices[billingPeriod].price;
    const vat = prices[billingPeriod].vat;
    const priceSuffix = ", billed " + (billingPeriod === VirtualOfficeBillingPeriod.Monthly ? "monthly" : "annually");

    return {
      title: "Registered office service",
      description: "Australian office address for company registration and mail handling",
      price,
      priceText: price + priceSuffix,
      vat,
      priceTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(696) + "/year" : undefined,
      vatTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(69.6) : undefined,
    };
  }
}
