import { makeAutoObservable } from "mobx";
import { type ProfileClient } from "./profileClient";
import { type ProfileData } from "./profileTypes";

export class ProfileStore {
  constructor(private readonly _profile: ProfileData) {
    makeAutoObservable<ProfileStore, "_client">(this, { _client: false });
  }

  get profile(): ProfileData {
    return this._profile;
  }

  private static async _loadProfile(client: ProfileClient): Promise<ProfileData> {
    try {
      return await client.getProfile();
    } catch (e) {
      console.debug("ProfileStore Error:", e);
      throw e;
    }
  }

  static async create(client: ProfileClient): Promise<ProfileStore> {
    const profile = await ProfileStore._loadProfile(client);
    return new ProfileStore(profile);
  }
}
