import { type HttpHandler, type HttpRequestParamsOverride, type HttpResponseOverride } from "@anna-money/anna-web-lib";
import { type AuthStore } from "./authStore";
import { type Authenticator } from "./authenticator";
import { UnauthenticatedError } from "./errors";

export class AuthenticationHandler implements HttpHandler {
  private readonly _authenticator: Authenticator;
  private readonly _authStore: AuthStore;

  constructor(authenticator: Authenticator, authStore: AuthStore) {
    this._authenticator = authenticator;
    this._authStore = authStore;
  }

  async handleRequest(request: HttpRequestParamsOverride): Promise<HttpRequestParamsOverride> {
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${this._authenticator.accessToken}`,
      },
    };
  }

  async handleResponse(_: HttpRequestParamsOverride, response: HttpResponseOverride<any>): Promise<void> {
    if (response.status === 401) {
      this._authStore.reset();
      throw new UnauthenticatedError();
    }
  }
}
