export enum IdentityQuestion {
  Identity = "IDENTITY_WIDGET",
  IdentityCompliance = "IDENTITY_COMPLIANCE",
  Poa = "IDENTITY_PROOF_OF_ADDRESS",
}

export const IDENTITY_FIELD_TO_PATH: Record<IdentityQuestion, string> = {
  [IdentityQuestion.Identity]: "id",
  [IdentityQuestion.IdentityCompliance]: "check",
  [IdentityQuestion.Poa]: "poa",
};
