import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { CompanyAcnQuestion } from "./companyConstants";

export class CompanyQuestionsStore extends QuestionsStore<CompanyAcnQuestion> {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(CompanyAcnQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): CompanyAcnQuestion | undefined {
    const { company } = this._companyStore;

    if (!company?.companyName) {
      return CompanyAcnQuestion.Name;
    }

    if (!company.pricingPlan) {
      return CompanyAcnQuestion.PricingPlan;
    }

    if (!company.stateOfRegistration) {
      return CompanyAcnQuestion.Jurisdiction;
    }

    if (!this._companyAddressStore.address) {
      return CompanyAcnQuestion.Address;
    }

    if (typeof this._companyAddressStore.address.isOwnedByCompany === "undefined") {
      return CompanyAcnQuestion.Occupier;
    }

    return undefined;
  }
}
