import { type FC, useEffect } from "react";
import { useCookies } from "react-cookie";
import { hotjar } from "react-hotjar";
import { useServices } from "appServices";

export const Hotjar: FC = () => {
  const { userStore, analyticsManager, config } = useServices();
  const [cookies] = useCookies(["anna_analytics_session_id"]);
  const sessionId = cookies.anna_analytics_session_id;
  useEffect(() => {
    hotjar.initialize(3520718, 6);
    const payload = {
      alias: userStore.user.alias,
      session_id: sessionId,
      region: config.region,
    };
    analyticsManager.event("hotjar-identify", payload);
    hotjar.identify(null, payload);
  }, []);

  return null;
};
