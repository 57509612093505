import "@anna-money/anna-web-ui/dist/index.css";
import { getReversed } from "@anna-money/anna-web-lib";
import { AnnaApp, InteractionManager, WindowManager } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react";
import React, { type FC, useEffect, useMemo } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AppBootstrapStore } from "./appBootstrapStore";
import { type AppProfileConfig, filterComponentSections } from "./appProfileConfig";
import { AppServices, type TServices } from "./appServices";
import { AuthCapturePage, AuthFinishPage, AuthStartPage, AuthTokenRefreshPage } from "./auth/authPages";
import Footer from "./components/Footer";
import { FormErrorView } from "./components/FormErrorView/FormErrorView";
import { Header } from "./components/Header/Header";
import { Hotjar } from "./components/Hotjar/Hotjar";
import { Loader, LoaderMode } from "./components/Loader/Loader";
import { Terms } from "./components/Terms/Terms";
import { UserFlowGuard } from "./components/UserStatusCheckGuard/UserFlowGuard";
import { WhyVirtualOffice } from "./components/WhyVirtualOffice/WhyVirtualOffice";
import { getConfig } from "./config/config";
import type { BaseServices } from "./services";
import { extractPageAnalytics } from "./services/analytics/analyticsHelpers";

import "./styles/globals.scss";
import styles from "./styles/scaffold.module.scss";

type AppProps = {
  profile: AppProfileConfig;
};

const config = getConfig();

type UnauthenticatedAreaProps = {
  services: BaseServices;
};

const UnauthenticatedArea: FC<UnauthenticatedAreaProps> = ({ services }): React.ReactElement => (
  <Routes>
    <Route path="terms" element={<Terms />} />
    <Route path="why-virtual-office" element={<WhyVirtualOffice />} />
    <Route path="auth/finish" element={<AuthFinishPage services={services} />} />
    <Route path="auth/refresh" element={<AuthTokenRefreshPage services={services} />} />
    <Route path="auth" element={<AuthStartPage services={services} redirect="/auth/finish" />} />
    <Route path="*" element={<AuthCapturePage services={services} redirect="/auth" />} />
  </Routes>
);

type AuthenticatedAreaProps = {
  profile: AppProfileConfig;
  services: TServices;
};

const AuthenticatedArea: FC<AuthenticatedAreaProps> = observer(({ profile, services }) => {
  const { config, analyticsManager } = services;
  const location = useLocation();

  useEffect(() => {
    // during first render we log it without alias cause auth still in progress
    analyticsManager.event("page_view", {
      raw_url: window.location.origin + location.pathname,
      ...extractPageAnalytics(window.location.href),
    });
  }, [location.pathname]);

  return (
    <InteractionManager>
      <AppServices services={services}>
        <UserFlowGuard>
          <Routes>
            {getReversed(filterComponentSections(profile.sections)).map((x) => (
              <Route key={x.path} path={`${x.path}*`} element={<x.component path={x.path} />} />
            ))}
            <Route path="*" element={<Navigate to={profile.sections[0].path} replace />} />
          </Routes>
        </UserFlowGuard>
        {config.withHotjar === "1" ? <Hotjar /> : null}
      </AppServices>
    </InteractionManager>
  );
});

export const AppComponent: FC<AppProps> = observer(({ profile }) => {
  const navigate = useNavigate();
  const bootstrapStore = useMemo(() => new AppBootstrapStore(config), []);
  const baseServices = bootstrapStore.baseServices;
  const isAuthenticated = baseServices.authStore.state === "authenticated";

  useEffect(() => {
    if (isAuthenticated) {
      void bootstrapStore.initialise(navigate);
    }
  }, [bootstrapStore, isAuthenticated]);

  const renderContent = (): React.ReactNode => {
    if (!isAuthenticated) {
      return <UnauthenticatedArea services={bootstrapStore.baseServices} />;
    }
    if (bootstrapStore.state === "loading") {
      return <Loader mode={LoaderMode.Splashscreen} size="xxl" />;
    }
    if (bootstrapStore.state === "error") {
      return <FormErrorView />;
    }
    return <AuthenticatedArea services={bootstrapStore.state.services} profile={profile} />;
  };

  return (
    <AnnaApp>
      <CookiesProvider>
        <WindowManager>
          <div className={styles.scaffold}>
            <Header className={styles.header} />
            <div className={styles.content}>{renderContent()}</div>
            <Footer />
          </div>
        </WindowManager>
      </CookiesProvider>
    </AnnaApp>
  );
});

export const App: FC<AppProps> = ({ profile }) => {
  return (
    <BrowserRouter>
      <AppComponent profile={profile} />
    </BrowserRouter>
  );
};
