import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class IssueData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly companyId: string,
    @JsonProperty() readonly created: string,
    @JsonProperty() readonly updated: string,
    @JsonProperty() readonly path: string,
    @JsonProperty() readonly chResponse: string,
    @JsonProperty({ type: String, isArray: true }) readonly clarifications: string[],
    @JsonProperty() readonly cta: string,
    @JsonProperty({ type: Boolean }) readonly resolved: boolean,
  ) {}
}

@Serializable()
export class IssueListData {
  constructor(@JsonProperty({ type: IssueData, isArray: true }) readonly companyIssues: IssueData[]) {}
}
