import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class AddressAutocompleteData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly addressText: string,
  ) {}
}

@Serializable()
export class AddressGroupAutocompleteData {
  constructor(
    @JsonProperty() readonly groupText: string,
    @JsonProperty({ type: AddressAutocompleteData, isArray: true })
    readonly addresses: AddressAutocompleteData[],
  ) {}
}

@Serializable()
export class AddressAutocompleteResponseData {
  constructor(
    @JsonProperty({ type: AddressAutocompleteData, isArray: true })
    readonly addresses: AddressAutocompleteData[],
  ) {}
}

@Serializable()
export class ResolveAutocompleteData {
  constructor(@JsonProperty() readonly autocompleteId: string) {}
}

@Serializable()
export class ResolveAutocompleteResponseData {
  constructor(
    @JsonProperty({ type: String, required: false }) readonly premise: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly street: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly town: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly county: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly country: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly postcode: Nullable<string>,
  ) {}
}
