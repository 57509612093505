import {
  AnnaErrorBase,
  ApiError,
  type HttpRequestDiagnostics,
  HttpRequestError,
  NetworkError,
  type Nullable,
  UnexpectedValueError,
  ValidationApiError,
  unwrapError,
} from "@anna-money/anna-web-lib";
import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { type ErrorEvent } from "@sentry/types";
import { reaction } from "mobx";
import { UnauthenticatedError } from "auth/errors";
import type { UserStore } from "services/user/userStore";
import type { UserData } from "services/user/userTypes";

let preventSending = false;
window.addEventListener("beforeunload", () => {
  preventSending = true;
  // In case if we didn't actually leave
  setTimeout(() => {
    preventSending = false;
  }, 100);
});

window.addEventListener("pagehide", () => {
  preventSending = true;
});

window.addEventListener("pageshow", () => {
  preventSending = false;
});

export class SentryHost {
  private static _initialised = false;

  static initialise(sentryDSN: string, appVersion: string): void {
    Sentry.init({
      allowUrls: [/https?:\/\/([^.]+\.)*anna.money/i],
      dsn: sentryDSN,
      release: appVersion,
      integrations: [
        new CaptureConsole({
          levels: ["warn", "error"],
        }),
      ],
      beforeSend: (event, hint) => {
        if (preventSending || shouldIgnoreEvent(event)) {
          return null;
        }

        const error = hint?.originalException;
        if (!(error instanceof Error)) {
          return event;
        }

        // We can't do anything about them
        if (unwrapError(error, UnauthenticatedError) || unwrapError(error, NetworkError)) {
          return null;
        }

        cleanupStackTrace(event);

        event.extra = event.extra || {};

        // We expect Sentry to handle cause's stack trace but still logging it here
        // in case the cause had some custom properties defined
        event.extra["Error cause"] = error.cause;

        const annaError = unwrapError(error, AnnaErrorBase);
        if (annaError) {
          event.extra["Error extra"] = annaError.extra;
        }

        const httpRequestError = unwrapError(error, HttpRequestError);
        if (httpRequestError) {
          event.tags = event.tags || {};
          event.tags["request.method"] = httpRequestError.diagnostics.request.method.toLowerCase();
          event.tags["request.baseUrl"] = httpRequestError.diagnostics.baseUrl;
          event.tags["request.url"] = httpRequestError.diagnostics.request.url;
          event.tags["request.httpCode"] = httpRequestError.diagnostics.httpCode;
          event.tags["request.apiCode"] = httpRequestError instanceof ApiError ? httpRequestError.code : undefined;

          event.extra["Request diagnostics"] = redactDiagnostics(httpRequestError.diagnostics);
          if (httpRequestError instanceof ValidationApiError) {
            event.extra["API validation errors"] = httpRequestError.getErrors();
          }
        }

        return event;
      },
    });
    SentryHost._initialised = true;
  }

  configure(userStore: UserStore): void {
    if (!SentryHost._initialised) {
      return;
    }
    reaction(
      () => userStore.user,
      (user) => {
        this._setUser(user);
      },
      { fireImmediately: true },
    );
  }

  private _setUser(user: UserData): void {
    Sentry.setUser({ id: user.alias });
  }
}

const errorConstructorRegex = /^(?:[A-Z]\w*)?Error/;

/**
 * Removing Error classes constructors from stack trace
 */
function cleanupStackTrace(event: ErrorEvent): void {
  const error = event.exception?.values?.[0];
  if (!error) {
    return;
  }
  const frames = error.stacktrace?.frames;
  if (!frames) {
    return;
  }
  for (const frame of frames) {
    if (frame.function && errorConstructorRegex.test(frame.function)) {
      frame.in_app = false;
    }
  }
}

const errorFilters = [
  // Browser extensions
  { stack: "chrome-extension://" },
  { message: "chrome-extension://" },
  { message: "StreamMiddleware - Unknown response id" },
  { message: "ObjectMultiplex - malformed chunk" },
  { message: "ethereum.initializeProvider is not a function" },
  { message: "'tronWeb.sidechain' is deprecated" },
  { message: "Running analytics_debug.js" },
  { message: "Object Not Found Matching" },
  { message: "There was an error setting cookie `_pk_" },
  // Stripe
  { message: "Failed to load Stripe.js" },
  // Generic
  { message: /can't redefine.*userAgent/i },
  { message: "Not implemented on this platform" },
] as const;

function shouldIgnoreEvent(event: ErrorEvent): boolean {
  const error = event.exception?.values?.[0];
  for (const filter of errorFilters) {
    if (
      "message" in filter &&
      (matchesFilter(event.message, filter.message) || matchesFilter(error?.value, filter.message))
    ) {
      return true;
    }
    if ("stack" in filter && error?.stacktrace?.frames?.some((x) => matchesFilter(x.filename, filter.stack))) {
      return true;
    }
  }
  return false;
}

function matchesFilter(value: Nullable<string>, filter: string | RegExp): boolean {
  if (!value) {
    return false;
  }
  if (typeof filter === "string") {
    return value.toLowerCase().includes(filter.toLowerCase());
  }
  if (filter instanceof RegExp) {
    return filter.test(value);
  }
  throw new UnexpectedValueError("filter", filter);
}

function redactDiagnostics(diagnostics: HttpRequestDiagnostics): HttpRequestDiagnostics {
  const headers = diagnostics.request.headers;
  const authorizationHeader = headers ? Object.keys(headers).find((x) => x.toLowerCase() === "authorization") : null;
  if (!authorizationHeader) {
    return diagnostics;
  }
  const redactedHeaders = { ...headers };
  redactedHeaders[authorizationHeader] = "<redacted>";
  return {
    ...diagnostics,
    request: {
      ...diagnostics.request,
      headers: redactedHeaders,
    },
  };
}
