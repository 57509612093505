import { type AddressClient } from "services/address/addressClient";
import { type CompanyStore } from "services/company/companyStore";
import { CompanyAddressStoreBase } from "services/companyAddress/companyAddressStoreBase";
import type { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import type { PricingPlanStore } from "services/pricingPlan/au/pricingPlanStore";

export class CompanyAddressStore extends CompanyAddressStoreBase {
  constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
    protected readonly _companyProductStore: CompanyProductsStore,
    protected readonly _pricingPlanStore: PricingPlanStore,
  ) {
    super(_companyStore, _addressClient, _companyProductStore, _pricingPlanStore);
  }
}
