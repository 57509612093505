import { type ApiClient } from "@anna-money/anna-web-lib";

import { UserData, type UserFlowTypeUpdateData, UserStatusData } from "./userTypes";

export class UserClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getUser(): Promise<UserData> {
    const response = await this._apiClient.get("api/formations/me");

    return response.getData(UserData);
  }

  async updateFlowType(data: UserFlowTypeUpdateData): Promise<UserData> {
    const response = await this._apiClient.post("api/formations/me", data);

    return response.getData(UserData);
  }

  async getUserStatus(): Promise<UserStatusData> {
    const response = await this._apiClient.get("api/formations/check-state");

    return response.getData(UserStatusData);
  }
}
