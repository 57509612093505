import { PricingPlan, type PricingPlanGb } from "services/pricingPlan/pricingPlanTypes";

export const isGbPricingPlan = (pricingPlan: PricingPlan): pricingPlan is PricingPlanGb => {
  return [
    PricingPlan.JustRegister,
    PricingPlan.FullFormation,
    PricingPlan.FullFormation19,
    PricingPlan.BusinessSecretary,
    PricingPlan.AllInOne,
  ].includes(pricingPlan);
};
