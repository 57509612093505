import dayjs, { type Dayjs, type ManipulateType, type OpUnitType, type QUnitType } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export class Datetime {
  private readonly _value: Dayjs;

  constructor(datetime: Dayjs) {
    this._value = datetime;
  }

  get year(): number {
    return this._value.year();
  }

  get month(): number {
    return this._value.month();
  }

  get date(): number {
    return this._value.date();
  }

  get hour(): number {
    return this._value.hour();
  }

  get minutes(): number {
    return this._value.minute();
  }

  format(format?: string): string {
    return this._value.format(format);
  }

  toISOString(): string {
    return this._value.toISOString();
  }

  add(value: number, unit?: ManipulateType): Datetime {
    return new Datetime(this._value.add(value, unit));
  }

  subtract(value: number, unit?: ManipulateType): Datetime {
    return new Datetime(this._value.subtract(value, unit));
  }

  diff(datetime: Datetime, unit?: QUnitType | OpUnitType, float?: boolean): number {
    return this._value.diff(datetime.toISOString(), unit, float);
  }

  isSame(datetime: Datetime, unit?: OpUnitType): boolean {
    return this._value.isSame(datetime.toISOString(), unit);
  }

  isAfter(datetime: Datetime, unit?: OpUnitType): boolean {
    return this._value.isAfter(datetime.toISOString(), unit);
  }

  static parse(datetime: string): Datetime {
    return new Datetime(dayjs.utc(datetime));
  }
}
