import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";

import { notNull } from "helpers/value";
import { RegisterFormStep } from "./registerFormStep";

export class RegisterFormSteps<T> {
  private readonly _steps: Array<RegisterFormStep<T>>;
  private _activeStepIndex?: number;

  constructor(steps: Array<RegisterFormStep<T> | null>) {
    this._steps = steps.filter(notNull);

    makeObservable(this, {
      _activeStepIndex: observable,
    } as any);
  }

  *[Symbol.iterator](): Generator<RegisterFormStep<T>> {
    for (let index = 0; index < this._steps.length; index++) {
      yield this._steps[index];
    }
  }

  get total(): number {
    return this._steps.length;
  }

  get activeStepIndex(): number {
    if (typeof this._activeStepIndex === "undefined") {
      throw new AnnaError("Active step is not defined, seems like a problem with a form init process");
    }
    return this._activeStepIndex;
  }

  get activeStep(): RegisterFormStep<T> {
    return this._steps[this.activeStepIndex];
  }

  get nextStep(): RegisterFormStep<T> | undefined {
    if (typeof this._activeStepIndex === "undefined") {
      return undefined;
    }

    const nextStepIndex = this._activeStepIndex + 1;

    if (nextStepIndex > this._steps.length - 1) {
      return undefined;
    }

    return this._steps[nextStepIndex];
  }

  get previousStep(): RegisterFormStep<T> | undefined {
    if (typeof this._activeStepIndex === "undefined") {
      return undefined;
    }

    const prevStepIndex = this._activeStepIndex - 1;

    if (prevStepIndex < 0) {
      return undefined;
    }

    return this._steps[prevStepIndex];
  }

  activateStep(activatingStepType: T | undefined): void {
    if (!activatingStepType) {
      return;
    }
    this._activeStepIndex = this._steps.findIndex((step) => step.type === activatingStepType);
  }
}
