import { type Stripe, type StripeElements } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { makeAutoObservable } from "mobx";

import { type Config } from "config/config";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type StripeIntegrationClient } from "services/stripeIntegration/stripeIntegrationClient";
import { type FormSubmitResult, FormValidationError } from "types/form";

type StripeFormState = "pending" | "loading" | "error" | StripeFormLoadedState;

export class StripeFormLoadedState {
  constructor(
    stripe: Stripe,
    clientSecret: string,
    private readonly _analyticsManager: AnalyticsManager,
  ) {
    this.stripe = stripe;
    this.clientSecret = clientSecret;
    makeAutoObservable(this);
  }

  readonly stripe: Stripe;

  readonly clientSecret: string;

  async confirm(elements: StripeElements, returnUrl: string): Promise<FormSubmitResult> {
    this._analyticsManager.event("stripe-submit");
    const result = await this.stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (result.error) {
      this._analyticsManager.event("stripe-submit-error", { error: result.error.message });
      return new FormValidationError(result.error.message || "Something went wrong");
    }
  }
}

export class StripeFormStore {
  private _state: StripeFormState = "pending";

  constructor(
    private readonly _stripeIntegrationClient: StripeIntegrationClient,
    private readonly _analyticsManager: AnalyticsManager,
    private readonly _config: Config,
  ) {
    makeAutoObservable(this);
  }

  get state(): StripeFormState {
    return this._state;
  }

  private set state(value: StripeFormState) {
    this._state = value;
  }

  async init(): Promise<void> {
    if (this._state !== "pending") {
      return;
    }

    this._analyticsManager.event("stripe-loading");
    this._state = "loading";

    try {
      const stripe = await loadStripe(this._config.stripeApiKey);

      if (!stripe) {
        console.error(new Error("Stripe was not initialized"));
        this.state = "error";
        return;
      }

      const paymentMethod = await this._stripeIntegrationClient.beginSetupPaymentMethod();

      this.state = new StripeFormLoadedState(stripe, paymentMethod.clientSecret, this._analyticsManager);
    } catch (e) {
      this.state = "error";
      this._analyticsManager.event("stripe-error");
      console.error(e);
    }
  }
}
