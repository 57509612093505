import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum UserCluster {
  Control = 0,
  Cluster1 = 1,
  Cluster2 = 2,
}

@Serializable()
export class UserClusterData {
  constructor(@JsonProperty({ type: Number }) readonly clusterNum: UserCluster) {}
}
