import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type IncorporationCreateData,
  IncorporationData,
  IncorporationListData,
} from "services/incorporation/incorporationTypes";

export class IncorporationClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getIncorporation(): Promise<IncorporationData | undefined> {
    const response = await this._apiClient.get("api/formations/incorporations");
    const incorporations = response.getData(IncorporationListData).incorporations;

    return incorporations.length ? incorporations[0] : undefined;
  }

  async createIncorporation(data: IncorporationCreateData): Promise<IncorporationData> {
    const response = await this._apiClient.post("api/formations/incorporations", data);

    return response.getData(IncorporationData);
  }
}
