import { AnnaError } from "@anna-money/anna-web-lib";

import { type NavigateFunction } from "react-router-dom";
import { type Config } from "config/config";
import { Page } from "pages";
import { extractPageAnalytics } from "services/analytics/analyticsHelpers";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type CompanyClient } from "services/company/companyClient";
import {
  CompanyCreateData,
  type CompanyData,
  CompanyNamePostfix,
  CompanyNameUpdateData,
} from "services/company/companyTypes";
import { type LeadClient } from "services/lead/leadClient";
import { LeadCreateData } from "services/lead/leadTypes";
import { type UserStore } from "services/user/userStore";

export const companyNameUrlParam = "company_name";
export const companyTypeUrlParam = "company_type";

export abstract class SignUpPageStoreBase {
  protected abstract _defaultPostfix: CompanyNamePostfix;

  protected constructor(
    protected readonly _companyClient: CompanyClient,
    protected readonly _leadClient: LeadClient,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _userStore: UserStore,
    protected readonly _navigate: NavigateFunction,
    protected readonly _config: Config,
  ) {}

  protected get finalPage(): Page {
    return Page.Main;
  }

  async init(): Promise<void> {
    try {
      await this._proceedSignUp();
    } catch (error: unknown) {
      this._analyticsManager.event("load-form-error");

      console.error(`Couldn‘t create company on sign up: ${(error as Error).toString()}`);
    } finally {
      this._analyticsManager.event("signup");
      this._analyticsManager.googleEvent("sign_up");

      this._navigate(this.finalPage);
    }
  }

  protected async _proceedSignUp(): Promise<undefined | CompanyData> {
    if (!this._userStore.status.canProceed) {
      return;
    }

    const searchParams = this._extractSearchParams();
    const companyName = this._getCompanyName(searchParams);
    const companyPostfix = this._getCompanyPostfix(searchParams);

    if (!companyName) {
      this._analyticsManager.event("signup-without-company-name-error");
      return;
    }

    let company = await this._companyClient.getCompany();
    if (company) {
      if (company.companyName !== companyName || company.namePostfix !== companyPostfix) {
        company = await this._companyClient.updateCompany(
          company.id,
          new CompanyNameUpdateData(companyName, companyPostfix),
        );
      }
      return company;
    }

    company = await this._companyClient.createCompany(new CompanyCreateData(companyName, companyPostfix));

    await this._saveLead();

    return company;
  }

  private async _saveLead(): Promise<void> {
    try {
      const lead = extractPageAnalytics(window.location.href);
      if (lead && Object.keys(lead).length) {
        await this._leadClient.saveLead(new LeadCreateData(lead));

        this._analyticsManager.event("save-lead-payload", {
          lead: JSON.stringify(lead),
        });
      }
    } catch (error: unknown) {
      this._analyticsManager.event("save-lead-error");

      throw new AnnaError(`Couldn‘t save lead on sign up: ${(error as Error).toString()}`);
    }
  }

  private _getCompanyName(searchParams: URLSearchParams): string | null {
    return searchParams.get(companyNameUrlParam);
  }

  private _getCompanyPostfix(searchParams: URLSearchParams): CompanyNamePostfix {
    const companyType = searchParams.get(companyTypeUrlParam) as CompanyNamePostfix;

    if (!companyType) {
      return this._defaultPostfix;
    }

    if (Object.values(CompanyNamePostfix).includes(companyType)) {
      return companyType;
    }

    // make company type coming from landing page the same as we have here and remove normalization
    const normalizedCompanyType = companyType.charAt(0).toUpperCase() + companyType.slice(1).toLowerCase();
    return CompanyNamePostfix[normalizedCompanyType as keyof typeof CompanyNamePostfix];
  }

  protected _extractSearchParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }
}
