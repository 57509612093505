import classNames from "classnames";
import { type FC, type ReactNode } from "react";

import styles from "./Text.module.scss";

export interface TextProps {
  children: ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  weight?: "regular" | "semibold";
  size?: "s" | "m" | "l";
  color?: "default" | "white";
  className?: string;
}

export const Text: FC<TextProps> = ({ children, tag = "span", weight = "regular", size = "m", color, className }) => {
  const Tag = tag;

  return (
    <Tag
      className={classNames(
        styles.text,
        styles[`size-${size}`],
        styles[`weight-${weight}`],
        styles[`color-${color}`],
        className,
      )}
    >
      {children}
    </Tag>
  );
};
