import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

import { makeAutoObservable } from "mobx";
import { DataStorage } from "services/storage/dataStorage";

export enum DirectorPosition {
  DirectorAndShareholder = "Director and Shareholder",
  Shareholder = "Shareholder",
  Director = "Director",
}

@Serializable()
export class DirectorPositionPayload {
  constructor(
    @JsonProperty({ type: String }) readonly id: string,
    @JsonProperty({ type: DirectorPosition }) readonly position: DirectorPosition,
  ) {}
}

@Serializable()
export class DirectorPositionsPayload {
  constructor(
    @JsonProperty({ type: DirectorPositionPayload, isArray: true }) readonly positions: DirectorPositionPayload[],
  ) {}
}

export type DirectorPositionsState = Record<string, DirectorPosition>;

export const isShareholder = (position?: DirectorPosition): boolean | undefined => {
  return position
    ? [DirectorPosition.DirectorAndShareholder, DirectorPosition.Shareholder].includes(position)
    : undefined;
};

export const isDirector = (position?: DirectorPosition): boolean => {
  return position ? [DirectorPosition.DirectorAndShareholder, DirectorPosition.Director].includes(position) : false;
};

const DIRECTOR_POSITIONS_STORAGE_KEY = "director_positions";

export class DirectorPositionStore {
  private readonly _dataStorage: DataStorage;
  private readonly _state: DirectorPositionsState;

  constructor(dataStorage: DataStorage) {
    this._dataStorage = dataStorage;

    const storedPostions =
      this._dataStorage.get(DIRECTOR_POSITIONS_STORAGE_KEY, DirectorPositionsPayload)?.positions || [];

    this._state = storedPostions.reduce<DirectorPositionsState>((result, item) => {
      result[item.id] = item.position;
      return result;
    }, {});

    makeAutoObservable(this);
  }

  get state(): DirectorPositionsState {
    return this._state;
  }

  get hasInfo(): boolean {
    return Object.keys(this._state).length > 0;
  }

  getForDirector(directorId: string): DirectorPosition | undefined {
    return this._state[directorId];
  }

  setForDirector(directorId: string, position: DirectorPosition): void {
    this._state[directorId] = position;

    this.storePositions();
  }

  private storePositions(): void {
    this._dataStorage.set(
      DIRECTOR_POSITIONS_STORAGE_KEY,
      Object.keys(this._state).reduce<DirectorPositionsPayload>((result, directorId) => {
        result.positions.push(new DirectorPositionPayload(directorId, this._state[directorId]));

        return result;
      }, new DirectorPositionsPayload([])),
    );
  }
}
