import { makeAutoObservable } from "mobx";

import type { BusinessActivitiesClient } from "services/businessActivities/businessActivitiesClient";
import { type BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import type { BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";
import { type CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import type { LazyLoadableState } from "types/loadableState";

type BusinessActivitiesSelectState = LazyLoadableState<"success">;

export class BusinessActivitiesSelectStore {
  private _state: BusinessActivitiesSelectState = "idle";
  private _activities: BusinessActivityData[] = [];

  constructor(
    private readonly _businessActivitiesClient: BusinessActivitiesClient,
    private readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
  ) {
    makeAutoObservable(this);

    this._setActivities(this._businessActivitiesStore.activities);
  }

  get state(): BusinessActivitiesSelectState {
    return this._state;
  }

  private set state(value: BusinessActivitiesSelectState) {
    this._state = value;
  }

  get activities(): BusinessActivityData[] {
    return this._activities;
  }

  private set activities(value: BusinessActivityData[]) {
    this._activities = value;
  }

  get deletingBlocked(): boolean {
    return this._companyBusinessActivitiesStore.activities.length === 1;
  }

  async searchActivities(query: string): Promise<BusinessActivityData[]> {
    if (!query) {
      this.reset();
      return this.activities;
    }

    await this._processAction(async () => {
      return await this._businessActivitiesClient.getBusinessActivities(query);
    });

    return this.activities;
  }

  async createActivity(activityId: string): Promise<void> {
    await this._processAction(async () => {
      await this._companyBusinessActivitiesStore.createActivity(activityId);
      return this.activities;
    });
  }

  async deleteActivity(activityId: string): Promise<void> {
    await this._processAction(async () => {
      await this._companyBusinessActivitiesStore.deleteActivity(activityId);
      return this.activities;
    });
  }

  reset(): void {
    this.activities = this._businessActivitiesStore.activities;
  }

  private async _processAction(action: () => Promise<BusinessActivityData[]>): Promise<void> {
    if (this.state === "loading") {
      return;
    }
    this.state = "loading";
    this._setActivities(await action());
    this.state = "success";
  }

  private _setActivities(activities: BusinessActivityData[]): void {
    this.activities = activities.filter(
      (activity) => !this._companyBusinessActivitiesStore.hasActivityWithId(activity.id),
    );
  }
}
