import classNames from "classnames";
import { type FC } from "react";
import { AppVersion } from "./components/AppVersion/AppVersion";
import Logo from "./components/Logo/Logo";

import styles from "./Header.module.scss";

interface HeaderProps {
  className?: string;
}

export const Header: FC<HeaderProps> = ({ className }) => (
  <div className={classNames(styles.header, className)}>
    <Logo />
    <AppVersion />
  </div>
);
