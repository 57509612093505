import { makeObservable, observable, reaction } from "mobx";
import { addressFormToCreateData, addressFormToUpdateData } from "helpers/forms/address";
import type { AddressFormValue } from "helpers/forms/addressTypes";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";
import { type CompanyStore } from "services/company/companyStore";
import type { CompanyData } from "services/company/companyTypes";
import type { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";
import type { IPricingPlanStore } from "services/pricingPlan/pricingPlanStoreBase";
import { FormError } from "types/errors";

export type UpdateAddressData =
  | {
      virtualOfficeBillingPeriod?: VirtualOfficeBillingPeriod;
    }
  | {
      address?: AddressFormValue;
    };

export abstract class CompanyAddressStoreBase {
  private _address?: AddressData;

  protected constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
    protected readonly _companyProductStore: CompanyProductsStore,
    protected readonly _pricingPlanStore: IPricingPlanStore,
  ) {
    makeObservable(this, {
      _address: observable,
    } as any);

    // some pricing plan includes virtual office, so we have to check on every update should we turn oi on or not
    reaction(() => [this._companyStore.company?.pricingPlan], this._onPricingPlanChange.bind(this));
  }

  get address(): undefined | AddressData {
    return this._address;
  }

  set address(value: undefined | AddressData) {
    this._address = value;
  }

  async init(): Promise<void> {
    const company = this._companyStore.company;
    if (company) {
      this.address = await this._addressClient.getAddress(AddressKey.Company, company.id);
    }
  }

  async updateAddress(data: UpdateAddressData): Promise<void> {
    let address, virtualOfficeBillingPeriod;

    if ("address" in data) {
      address = data.address;
    }

    if ("virtualOfficeBillingPeriod" in data) {
      virtualOfficeBillingPeriod = data.virtualOfficeBillingPeriod;
    }

    if (address) {
      await this._companyProductStore.deleteProduct(CompanyProduct.VirtualOffice);
      await this._updateAddress(false, address);
      return;
    }

    if (virtualOfficeBillingPeriod) {
      if (!this._pricingPlanStore.isProductIncluded(CompanyProduct.VirtualOffice)) {
        await this._companyProductStore.addProduct(CompanyProduct.VirtualOffice, virtualOfficeBillingPeriod);
      }
      await this._updateAddress(true);
      return;
    }

    throw new FormError("Please select address");
  }

  protected async _updateAddress(useVirtualOffice: boolean, address?: AddressFormValue): Promise<void> {
    const company = this._companyStore.getCompany();
    const changeFromVirtual = Boolean(company.useVirtualOffice) && !useVirtualOffice;

    await this._companyStore.updateVirtualOffice(useVirtualOffice);

    // We need to update address after setting useVirtualOffice because in case of
    // useVirtualOffice=true it will be changed to Hoxton address and in case of
    // useVirtualOffice=false existed address will be deleted
    const updatedAddress = await this._addressClient.getAddress(AddressKey.Company, company.id);

    if (useVirtualOffice) {
      this.address = updatedAddress;
      return;
    }

    if (!address) {
      throw new FormError("Please select address");
    }

    this.address = await this._createOrUpdateAddress(changeFromVirtual, company, address, updatedAddress);
  }

  protected async _createOrUpdateAddress(
    changeFromVirtual: boolean,
    company: CompanyData,
    newAddress: AddressFormValue,
    currentAddress?: AddressData,
  ): Promise<AddressData> {
    // update or create address in address service
    // odd logic about when use create or update
    const handler =
      changeFromVirtual || !currentAddress
        ? async () =>
            await this._addressClient.createAddress(addressFormToCreateData(newAddress, { companyId: company.id }))
        : async () => await this._addressClient.updateAddress(currentAddress.id, addressFormToUpdateData(newAddress));
    return await handler();
  }

  private async _onPricingPlanChange(): Promise<void> {
    if (this._companyStore.getCompany().useVirtualOffice) {
      return;
    }

    if (this._pricingPlanStore.isProductIncluded(CompanyProduct.VirtualOffice)) {
      await this.updateAddress({ virtualOfficeBillingPeriod: VirtualOfficeBillingPeriod.Yearly });
    }
  }
}
