import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type SicCodeData,
  SicCodesData,
  SicCodesRecommendData,
  type SicCodesRecommendRequestData,
} from "services/sicCodes/sicCodesTypes";

export class SicCodesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  getSicCodes = async (): Promise<SicCodeData[]> => {
    const response = await this._apiClient.get("api/formations/sic-codes");

    return response.getData(SicCodesData).sicCodes;
  };

  getRecommendedSicCodes = async (data: SicCodesRecommendRequestData): Promise<string[]> => {
    const response = await this._apiClient.post("api/formations/sic-codes/recommend", data);

    return response.getData(SicCodesRecommendData).recommendedSicCodes;
  };
}
