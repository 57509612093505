import { type ApiClient } from "@anna-money/anna-web-lib";
import { type UserCluster, UserClusterData } from "./userClusterTypes";

export class UserClusterClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async createCluster(data: UserClusterData): Promise<UserCluster> {
    const response = await this._apiClient.post("api/formations/cluster", data);
    return response.getData(UserClusterData).clusterNum;
  }

  async getCluster(): Promise<UserCluster> {
    const response = await this._apiClient.get("api/formations/cluster");
    return response.getData(UserClusterData).clusterNum;
  }
}
