import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import { PricingPlanStore } from "services/pricingPlan/au/pricingPlanStore";
import { type PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export interface PricingPlanFormValues {
  pricingPlan?: PricingPlanAu;
}

export class PricingPlanFormStore {
  constructor(private readonly _pricingPlanStore: PricingPlanStore) {}

  @FormSubmitMethod()
  async onSubmit(values: PricingPlanFormValues): Promise<FormSubmitResult> {
    if (!values.pricingPlan) {
      throw new FormError("No pricing plan selected");
    }

    await this._pricingPlanStore.updatePricingPlan(values.pricingPlan);
  }
}
