import type { CompanyData } from "services/company/companyTypes";
import {
  VirtualOfficeBillingPeriod,
  type VirtualOfficeProductDetailsData,
} from "services/companyProducts/companyProductsTypes";

export const getUseVirtualOffice = (
  company?: CompanyData,
  pricingPlanIncludesVirtualOffice?: boolean,
): boolean | undefined => {
  if (typeof company?.useVirtualOffice !== "undefined") {
    return company.useVirtualOffice;
  }

  if (pricingPlanIncludesVirtualOffice) {
    return true;
  }

  return undefined;
};

export const getVirtualOfficeBillingPeriod = (
  company?: CompanyData,
  virtualOfficeProductDetails?: VirtualOfficeProductDetailsData,
): VirtualOfficeBillingPeriod => {
  if (!company || !virtualOfficeProductDetails) {
    return VirtualOfficeBillingPeriod.Yearly;
  }

  return virtualOfficeProductDetails.billingPeriod;
};
