import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import { resolveAppProfileConfig } from "./appProfileConfig";
import { getConfig } from "./config/config";
import { SentryHost } from "./services/sentry/sentryHost";

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Can't find root element");
}

const root = createRoot(rootElement);
const config = getConfig();
const profile = resolveAppProfileConfig(config);

if (config.sentryDSN) {
  SentryHost.initialise(config.sentryDSN, config.appVersion);
}

root.render(
  <React.StrictMode>
    <App profile={profile} />
  </React.StrictMode>,
);
