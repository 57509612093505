import type { ParticularsFormValues } from "helpers/forms/particularsTypes";
import { CompanyStore } from "services/company/companyStore";
import { DirectorStore } from "services/director/directorStore";
import { FormError } from "types/errors";

export class ParticularsStepStore {
  private readonly _directorStore: DirectorStore;
  private readonly _companyStore: CompanyStore;

  constructor(directorStore: DirectorStore, companyStore: CompanyStore) {
    this._directorStore = directorStore;
    this._companyStore = companyStore;
  }

  async updateShares(formValues: ParticularsFormValues): Promise<void> {
    const { totalShares, totalCapital, directors: directorShares } = formValues || {};

    if (!totalShares || !totalCapital || !directorShares) {
      throw new FormError("Something went wrong");
    }

    await this._companyStore.updateShares(totalShares, totalCapital);

    for (const director of this._directorStore.directors) {
      const value = directorShares[director.id];
      await this._directorStore.updateShares(director.id, value ? parseInt(value) : undefined);
    }
  }
}
