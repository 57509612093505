import { type AddressFormValue } from "helpers/forms/addressTypes";
import { type AddressClient } from "services/address/addressClient";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyCountry } from "services/company/companyTypes";
import { CompanyAddressStoreBase } from "services/companyAddress/companyAddressStoreBase";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { COUNTRY_NAME_TO_CODE } from "services/incorporationRegisterForm/constants";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";

const resolveAddressToCountryCode = (country: string = ""): CompanyCountry | undefined => {
  return COUNTRY_NAME_TO_CODE[country.toUpperCase()];
};

export class CompanyAddressStore extends CompanyAddressStoreBase {
  constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
    protected readonly _companyProductStore: CompanyProductsStore,
    protected readonly _pricingPlanStore: PricingPlanStore,
  ) {
    super(_companyStore, _addressClient, _companyProductStore, _pricingPlanStore);
  }

  get canAlterCountry(): boolean {
    const useVirtualOffice = this._companyStore.getCompany().useVirtualOffice;
    const country = (this.address?.country ?? "").toUpperCase();

    return country === "WALES" && !useVirtualOffice;
  }

  protected async _updateAddress(useVirtualOffice: boolean, address?: AddressFormValue): Promise<void> {
    await super._updateAddress(useVirtualOffice, address);

    const country = resolveAddressToCountryCode(this.address?.country);
    if (country) {
      await this._companyStore.updateCountry(country);
    }
  }
}
