import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";

import { type CompanyStore } from "services/company/companyStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";
import { PricingPlan, type PricingPlanProducts } from "services/pricingPlan/pricingPlanTypes";
import { type PricingPlanClient } from "./pricingPlanClient";

export interface IPricingPlanStore {
  getPricingPlan: () => unknown;
  isProductIncluded: (product: CompanyProduct, pricingPlan?: PricingPlan) => boolean;
}

export abstract class PricingPlanStoreBase<T extends PricingPlan> implements IPricingPlanStore {
  private _pricingPlanProducts?: PricingPlanProducts;

  protected constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _companyProductsStore: CompanyProductsStore,
    protected readonly _pricingPlanClient: PricingPlanClient,
  ) {
    makeObservable(this, {
      _pricingPlanProducts: observable,
    } as any);
  }

  get pricingPlanProducts(): PricingPlanProducts | undefined {
    return this._pricingPlanProducts;
  }

  private set pricingPlanProducts(value) {
    this._pricingPlanProducts = value;
  }

  abstract isSupportedPricingPlan(pricingPlan: PricingPlan): pricingPlan is T;

  async init(): Promise<void> {
    this.pricingPlanProducts = await this._pricingPlanClient.getPricingPlanProducts();
  }

  tryGetPricingPlan(): T | undefined {
    const pricingPlan = this._companyStore.company?.pricingPlan;

    if (!pricingPlan) {
      return undefined;
    }

    if (!this.isSupportedPricingPlan(pricingPlan)) {
      throw new AnnaError(`Pricing plan ${pricingPlan} is not expected`);
    }

    return pricingPlan;
  }

  getPricingPlan(): T {
    const pricingPlan = this.tryGetPricingPlan();

    if (!pricingPlan) {
      throw new AnnaError("No pricing plan selected");
    }

    return pricingPlan;
  }

  async updatePricingPlan(pricingPlan: T): Promise<void> {
    // if user choose pricing plan which is already includes some products –
    // need to remove them if user chose them previously
    await this._deleteAllPlanProducts(pricingPlan);

    const oldPricingPlan = this.tryGetPricingPlan();
    await this._companyStore.updatePricingPlan(pricingPlan);

    await this._restoreVirtualOfficeIfNeeded(pricingPlan, oldPricingPlan);
  }

  isProductIncluded(product: CompanyProduct, pricingPlan?: PricingPlan): boolean {
    const plan = pricingPlan || this.tryGetPricingPlan();

    if (!plan) {
      return false;
    }

    return this._getProductsForPlan(plan).includes(product);
  }

  private _getProductsForPlan(pricingPlan: PricingPlan): CompanyProduct[] {
    return this.pricingPlanProducts ? this.pricingPlanProducts[pricingPlan] : [];
  }

  private async _deleteAllPlanProducts(pricingPlan: T): Promise<void> {
    const planProducts = this._getProductsForPlan(pricingPlan);
    for (const product of planProducts) {
      if (this._companyProductsStore.hasProduct(product)) {
        await this._companyProductsStore.deleteProduct(product);
      }
    }
  }

  private async _restoreVirtualOfficeIfNeeded(pricingPlan: T, oldPricingPlan?: T): Promise<void> {
    if (!oldPricingPlan || this.isProductIncluded(CompanyProduct.VirtualOffice, pricingPlan)) {
      return;
    }

    const needToRestoreVO = oldPricingPlan && this.isProductIncluded(CompanyProduct.VirtualOffice, oldPricingPlan);
    if (needToRestoreVO) {
      await this._companyProductsStore.addProduct(CompanyProduct.VirtualOffice, VirtualOfficeBillingPeriod.Yearly);
    }
  }
}
