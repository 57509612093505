import { JsonProperty, Serializable, Serializer } from "@anna-money/anna-web-lib";

export enum Region {
  GB = "GB",
  AU = "AU",
}

@Serializable()
export class Config {
  constructor(
    @JsonProperty() readonly appVersion: string,
    @JsonProperty() readonly annaApiUrl: string,
    @JsonProperty() readonly annaMediaApiUrl: string,
    @JsonProperty() readonly businessXHost: string,
    @JsonProperty() readonly annaAuthUrl: string,
    @JsonProperty() readonly annaAuthClientId: string,
    @JsonProperty() readonly sentryDSN: string,
    @JsonProperty() readonly stripeApiKey: string,
    @JsonProperty() readonly withHotjar: "0" | "1",
    @JsonProperty() readonly webticsTrackerUrl: string,
    @JsonProperty() readonly region: Region,
  ) {}

  get isAU(): boolean {
    return this.region === Region.AU;
  }
}

const serializer = new Serializer({ propertiesRequired: true });
const config: Config = serializer.deserialize(
  process.env.NODE_ENV === "production"
    ? window.getAppConfig()
    : window.getAppConfig(
        process.env.REACT_APP_ANNA_DEV_ENV || "expstudio.od",
        process.env.REACT_APP_ANNA_REGION || Region.GB,
      ),
  Config,
);

export const getConfig = (): Config => {
  return config;
};
