import { makeObservable, observable } from "mobx";
import type { AddressVerificationStore } from "services/addressVerification/addressVerificationStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { IdentityQuestion } from "services/questions/gb/identityConstants";
import { QuestionsStore } from "services/questions/questionsStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";

type IdentityQuestionsState = "default" | "error";

export class IdentityQuestionsStore extends QuestionsStore<IdentityQuestion> {
  private _state: IdentityQuestionsState = "default";

  constructor(
    private readonly _addressVerificationStore: AddressVerificationStore,
    private readonly _sumsubStore: SumsubApplicationStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(IdentityQuestion, _issuesStore);

    makeObservable(this, {
      _state: observable,
    } as any);
  }

  get state(): IdentityQuestionsState {
    return this._state;
  }

  set state(value: IdentityQuestionsState) {
    this._state = value;
  }

  onOpenbankingCheckError(): void {
    this.state = "error";
    this.goToQuestion(IdentityQuestion.Poa);
  }

  protected _shouldSkipQuestion(question: IdentityQuestion): boolean {
    switch (question) {
      case IdentityQuestion.Poa:
      case IdentityQuestion.PoaOpenbanking:
        return !this._addressVerificationStore.isPoaRequired;
    }

    return super._shouldSkipQuestion(question);
  }

  protected _getPreviousQuestionKey(): IdentityQuestion | undefined {
    const prevQuestionKey = super._getPreviousQuestionKey();

    switch (prevQuestionKey) {
      case IdentityQuestion.IdentityCompliance:
        return IdentityQuestion.Identity;
    }

    return prevQuestionKey;
  }

  protected _getRelevantQuestion(): IdentityQuestion | undefined {
    if (this._sumsubStore.isPassedVerifications) {
      return IdentityQuestion.IdentityCompliance;
    }

    return this.firstQuestion;
  }
}
