import { Button } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react";

import { type FC, useCallback } from "react";

import { useServices } from "appServices";
import { Paragraph } from "components/Paragraph/Paragraph";
import { Subtitle } from "components/Subtitle/Subtitle";
import styles from "./ExistedCustomer.module.scss";

export const ExistedCustomer: FC = observer(() => {
  const {
    profileStore,
    authStore,
    userStore: { status },
  } = useServices();
  const logout = useCallback(async () => {
    await authStore.logout((url) => {
      window.location.replace(url);
    });
  }, [authStore]);

  return (
    <div className={styles.page}>
      {status.blockedByOnboarding ? (
        <>
          <Subtitle>
            You already started setting up an account in the ANNA app with the email address{" "}
            <span className={styles.email}>{profileStore.profile?.email}</span>
          </Subtitle>

          <Paragraph>
            Please open the app and continue there – you‘ll be able to register a company once you‘ve set up your
            account
          </Paragraph>
        </>
      ) : (
        <>
          <Subtitle>
            The email address <span className={styles.email}>{profileStore.profile?.email}</span> is currently
            associated with a registered company <span className={styles.company}>{status.companies?.[0]}</span>
          </Subtitle>

          <Paragraph>
            If you’re looking to register new company with ANNA, please choose a different email address. Using a
            different email address for your new company will help to keep your various company accounts tidy.
          </Paragraph>
        </>
      )}

      <div className={styles.action}>
        <Button text="Use new email address" size="m" onClick={logout} />
      </div>
    </div>
  );
});
