import { makeAutoObservable, reaction } from "mobx";
import { BillingItem, CompanyProductToBillingItemMapper } from "services/checkoutBasket/checkoutBasketTypes";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";

const mapper = new CompanyProductToBillingItemMapper({
  [CompanyProduct.VirtualOffice]: BillingItem.VirtualOffice,
});

export class CheckoutBasketStore {
  private _addedItems: BillingItem[] = [];

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyProductsStore: CompanyProductsStore,
  ) {
    this._distributeItems();

    makeAutoObservable(this);

    reaction(
      () => [this._companyStore.company?.pricingPlan, this._companyProductsStore.productsData],
      this._distributeItems.bind(this),
    );
  }

  get addedItems(): BillingItem[] {
    return this._addedItems;
  }

  set addedItems(value: BillingItem[]) {
    this._addedItems = value;
  }

  private _distributeItems(): void {
    type ItemDistributionResult = { addedItems: BillingItem[] };
    const itemDistribution = mapper.billingItems.reduce<ItemDistributionResult>(
      (result, billingItem) => {
        switch (billingItem) {
          case BillingItem.VirtualOffice:
            if (this._companyProductsStore.hasProduct(CompanyProduct.VirtualOffice)) {
              result.addedItems.push(billingItem);
            }
            break;
        }

        return result;
      },
      { addedItems: [] },
    );

    this.addedItems = itemDistribution.addedItems;
  }
}
