import { type ApiClient, createSearchParams } from "@anna-money/anna-web-lib";

import { BusinessActivitiesData, type BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";

export class BusinessActivitiesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  getBusinessActivities = async (query?: string): Promise<BusinessActivityData[]> => {
    const response = await this._apiClient.get(
      `api/formations/business-activities?${createSearchParams({ q: query }).toString()}`,
    );

    return response.getData(BusinessActivitiesData).businessCodes;
  };
}
