import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type DirectorCreateData,
  DirectorData,
  DirectorListData,
  type DirectorSharesUpdateData,
  type DirectorUpdateData,
  type MainDirectorCreateData,
} from "services/director/directorTypes";

export class DirectorClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getDirectorList(): Promise<DirectorData[]> {
    const response = await this._apiClient.get("api/formations/v2/directors");
    return response.getData(DirectorListData).directors;
  }

  async createDirector(data: MainDirectorCreateData | DirectorCreateData): Promise<DirectorData> {
    const response = await this._apiClient.post("api/formations/v2/directors", data);

    return response.getData(DirectorData);
  }

  async updateDirector(directorId: string, data: DirectorUpdateData | DirectorSharesUpdateData): Promise<DirectorData> {
    const response = await this._apiClient.post(`api/formations/directors/${directorId}`, data);

    return response.getData(DirectorData);
  }

  async deleteDirector(directorId: string): Promise<void> {
    await this._apiClient.delete(`api/formations/directors/${directorId}`);
  }
}
