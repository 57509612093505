import { makeAutoObservable } from "mobx";
import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import { directorFormToDirectorCreateData, directorFormToDirectorUpdateData } from "helpers/forms/director";
import type { DirectorFormCommonValues } from "helpers/forms/directorTypes";
import type { CompanyStore } from "services/company/companyStore";
import { DirectorAddressStore } from "services/director/directorAddressStore";
import { DirectorAttributesStore } from "services/director/directorAttributesStore";
import { type DirectorStore } from "services/director/directorStore";
import { DirectorPositionStore } from "services/directorPosition/directorPositionStore";
import { FormError } from "types/errors";
import { type FormSubmitResult } from "types/form";

export enum DirectorFormStep {
  CommonData = "CommonData",
  Address = "Address",
  Attributes = "Attributes",
}

const STEPS = [DirectorFormStep.CommonData, DirectorFormStep.Address, DirectorFormStep.Attributes];

export class DirectorFormStore {
  private _activeStep: DirectorFormStep = DirectorFormStep.CommonData;
  private readonly _editMode: boolean;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _attributesStore: DirectorAttributesStore,
    private readonly _addressStore: DirectorAddressStore,
    private readonly _directorPositionsStore: DirectorPositionStore,
    private _directorId?: string,
  ) {
    this._editMode = !!_directorId;

    makeAutoObservable(this);
  }

  get activeStep(): DirectorFormStep {
    return this._activeStep;
  }

  set activeStep(value: DirectorFormStep) {
    this._activeStep = value;
  }

  get isFirstStep(): boolean {
    return this.activeStep === STEPS[0];
  }

  get isLastStep(): boolean {
    return this.activeStep === STEPS.at(-1);
  }

  get editMode(): boolean {
    return this._editMode;
  }

  getDirectorId(): string {
    if (!this._directorId) {
      throw new FormError("No director's id");
    }

    return this._directorId;
  }

  @FormSubmitMethod()
  async onSubmit(values: DirectorFormCommonValues): Promise<FormSubmitResult> {
    switch (this.activeStep) {
      case DirectorFormStep.CommonData:
        await this._updateCommonData(values);
        break;
      case DirectorFormStep.Address:
        await this._updateAddress(values);
        break;
      case DirectorFormStep.Attributes:
        await this._updateAttributes(values);
        break;
    }

    if (!this.isLastStep) {
      this._goForward();
    }
  }

  goBack(): void {
    this.activeStep = STEPS[STEPS.indexOf(this.activeStep) - 1];
  }

  private _goForward(): void {
    this.activeStep = STEPS[STEPS.indexOf(this.activeStep) + 1];
  }

  private async _updateCommonData(values: DirectorFormCommonValues): Promise<void> {
    if (!values.position) {
      throw new FormError("Something went wrong, no director's position in the form");
    }

    if (this._directorId) {
      await this._directorStore.updateDirector(this._directorId, directorFormToDirectorUpdateData(values));
    } else {
      this._directorId = await this._directorStore.createDirector(
        directorFormToDirectorCreateData(this._companyStore.getCompany().id, values),
      );
    }

    this._directorPositionsStore.setForDirector(this._directorId, values.position);
  }

  private async _updateAddress(values: DirectorFormCommonValues): Promise<void> {
    if (!values.address) {
      throw new FormError("Something went wrong, no address in the form");
    }

    await this._addressStore.updateForDirector(this.getDirectorId(), values.address);
  }

  private async _updateAttributes(values: DirectorFormCommonValues): Promise<void> {
    if (!values.attributes) {
      throw new FormError("Something went wrong, no attributes in the form");
    }

    await this._attributesStore.updateForDirector(this.getDirectorId(), values.attributes);
  }
}
