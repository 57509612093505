import { type ApiClient } from "@anna-money/anna-web-lib";

import { CheckStripeRequirementsResponseData } from "services/cardDetails/cardDetailsTypes";

export class CardDetailsClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async checkStripeRequirements(): Promise<CheckStripeRequirementsResponseData> {
    const response = await this._apiClient.get("api/formations/stripe/check-requirements");

    return response.getData(CheckStripeRequirementsResponseData);
  }
}
