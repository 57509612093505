import { AnnaError } from "@anna-money/anna-web-lib";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";

export enum BillingItem {
  VirtualOffice = "VirtualOffice",
  Taxes = "Taxes",
  VatRegistration = "VatRegistration",
  ConfirmationStatement = "ConfirmationStatement",
  CancellationCover = "CancellationCover",
  PayeRegistration = "PayeRegistration",
  FormationExpress = "FormationExpress",
}

export class CompanyProductToBillingItemMapper {
  constructor(private readonly _map: { [key in CompanyProduct]?: BillingItem }) {}

  get billingItems(): BillingItem[] {
    return Object.values(this._map);
  }

  getProduct(billingItem: BillingItem): CompanyProduct {
    for (const product of Object.keys(this._map) as CompanyProduct[]) {
      if (this._map[product] === billingItem) {
        return product;
      }
    }
    throw new AnnaError(`No product assosiated with the item ${billingItem}`);
  }
}
