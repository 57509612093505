import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import type { CompanyFormValues } from "helpers/forms/acn/companyTypes";
import type { CompanyStore } from "services/company/companyStore";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export type CompanyStateFormValues = Pick<CompanyFormValues, "stateOfRegistration">;

export class CompanyStateFormStore {
  constructor(private readonly _companyStore: CompanyStore) {}

  @FormSubmitMethod()
  async onSubmit(values: CompanyStateFormValues): Promise<FormSubmitResult> {
    if (!values.stateOfRegistration) {
      throw new FormError("No company name or name postfix selected");
    }

    await this._companyStore.updateCompanyStateOfRegistration(values.stateOfRegistration);
  }
}
