import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  SumsubApplication,
  type SumsubCheckFlowName,
  type SumsubCreateApplicationData,
  SumsubTokenRequest,
  SumsubTokenResponse,
} from "services/sumsub/sumsubTypes";

export class SumsubClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  getSumsubToken = async (flowType?: SumsubCheckFlowName): Promise<string> => {
    const response = await this._apiClient.post(
      "api/formations/sumsub/token",
      flowType ? new SumsubTokenRequest(flowType) : {},
    );

    return response.getData(SumsubTokenResponse).token;
  };

  getSumsubApplication = async (): Promise<SumsubApplication> => {
    const response = await this._apiClient.get("api/formations/sumsub/application");

    return response.getData(SumsubApplication);
  };

  createSumsubApplication = async (data: SumsubCreateApplicationData): Promise<void> => {
    await this._apiClient.post("api/formations/sumsub/application", data);
  };
}
