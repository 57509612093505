import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { type IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum CompanyQuestion {
  Name = "COMPANY_NAME",
  PricingPlan = "COMPANY_PRICING_PLAN",
  SameDayRegistration = "COMPANY_SAME_DAY_REGISTRATION",
  Address = "COMPANY_ADDRESS",
  Country = "COMPANY_COUNTRY",
  Description = "COMPANY_DESCRIPTION",
  Activities = "COMPANY_ACTIVITIES",
  Starting = "COMPANY_STARTING",
  AnnaTaxes = "COMPANY_INTERESTED_IN_ANNA_TAXES",
  VatRegistration = "COMPANY_VAT_REGISTRATION",
  ConfirmationStatement = "COMPANY_CONFIRMATION_STATEMENT",
}

export const ISSUE_FIELD_TO_QUESTION: IssueFieldQuestionMap<CompanyQuestion> = {
  company_name: CompanyQuestion.Name,
  name_postfix: CompanyQuestion.Name,
  country: CompanyQuestion.Address,
  address: CompanyQuestion.Address,
  nature_of_business: CompanyQuestion.Description,
  sic_codes: CompanyQuestion.Activities,
  starts_trading: CompanyQuestion.Starting,
};

export const QUESTION_TO_PRODUCT: { [key in CompanyQuestion]?: CompanyProduct } = {
  [CompanyQuestion.AnnaTaxes]: CompanyProduct.Taxes,
  [CompanyQuestion.VatRegistration]: CompanyProduct.VatRegistration,
  [CompanyQuestion.ConfirmationStatement]: CompanyProduct.ConfirmationStatement,
};

export const QUESTION_TO_PATH: Record<CompanyQuestion, string> = {
  [CompanyQuestion.Name]: "name",
  [CompanyQuestion.PricingPlan]: "package",
  [CompanyQuestion.SameDayRegistration]: "same-day-registration",
  [CompanyQuestion.Address]: "address",
  [CompanyQuestion.Country]: "country",
  [CompanyQuestion.Description]: "description",
  [CompanyQuestion.Activities]: "activities",
  [CompanyQuestion.Starting]: "starting",
  [CompanyQuestion.AnnaTaxes]: "taxes",
  [CompanyQuestion.VatRegistration]: "vat-registration",
  [CompanyQuestion.ConfirmationStatement]: "confirmation-statement",
};
