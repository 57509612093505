import { useEffect } from "react";

export function useMount(callback: () => void | Promise<void>, unmountCallback?: () => void): void {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    callback();

    return () => {
      if (unmountCallback) {
        unmountCallback();
      }
    };
  }, []);
}
