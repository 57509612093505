import classNames from "classnames";
import { type FC, type ReactNode } from "react";

import styles from "./Subtitle.module.scss";

interface SubtitleProps {
  children: ReactNode;
  className?: string;
}

export const Subtitle: FC<SubtitleProps> = ({ children, className }) => {
  return <h2 className={classNames(styles.subtitle, className)}>{children}</h2>;
};
