import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class BusinessActivityData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly code: string,
    @JsonProperty() readonly description: string,
  ) {}
}

@Serializable()
export class BusinessActivitiesData {
  constructor(
    @JsonProperty({ type: BusinessActivityData, isArray: true }) readonly businessCodes: BusinessActivityData[],
  ) {}
}
