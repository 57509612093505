import { type FC } from "react";

import { Link } from "components/Link/Link";
import { Paragraph } from "components/Paragraph/Paragraph";
import { Subtitle } from "components/Subtitle/Subtitle";
import styles from "./FormErrorView.module.scss";

export const FormErrorView: FC = () => {
  return (
    <div className={styles.formErrorView}>
      <img className={styles.image} src="/images/superman.svg" alt="superman" />

      <Subtitle className={styles.title}>For some reason, ANNA couldn’t load this page</Subtitle>
      <Paragraph className={styles.text}>But don’t worry all is not lost!</Paragraph>
      <Paragraph className={styles.text}>
        Try to reload this page. If the problem still persist, contact us at{" "}
        <Link className={styles.link} href="mailto:formations@anna.money">
          formations@anna.money
        </Link>
      </Paragraph>
    </div>
  );
};
