import { makeObservable, observable } from "mobx";
import { registerFormStepParam } from "auth/authPages";
import { isSomeEnum } from "helpers/enum";
import { getFromMapByKey } from "helpers/object";
import { getUrlParam } from "helpers/url";
import { ensured } from "helpers/value";
import { type IIssuesStore, type IssueFieldQuestionMap, type IssueKey } from "services/issues/issuesStore";

export class QuestionStoreError extends Error {}

export abstract class QuestionsStore<T> {
  protected _questionsArray: T[] = this._getQuestionsArray();
  protected _activeQuestionKey: T = this._questionsArray[0];
  protected _issueKey?: IssueKey;
  protected _issueFieldsToQuestions?: IssueFieldQuestionMap<T>;
  protected abstract _getRelevantQuestion(): T | undefined;

  protected constructor(
    protected _questions: Object,
    protected readonly _issuesStore: IIssuesStore,
  ) {
    makeObservable(this, {
      _activeQuestionKey: observable,
    } as any);
  }

  get activeQuestionKey(): T {
    return this._activeQuestionKey;
  }

  private set activeQuestionKey(value: T) {
    this._activeQuestionKey = value;
  }

  get firstQuestion(): T {
    return this._questionsArray[0];
  }

  private get activeQuestionIndex(): number {
    return this._questionsArray.indexOf(this.activeQuestionKey);
  }

  private get lastQuestion(): T {
    return ensured(this._findNextRelevantQuestion(this._questionsArray.length - 1, "backward"));
  }

  private get firstQuestionWithIssue(): T | undefined {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issue = this._issuesStore.getFirstIssueForKey(this._issueKey);

    return getFromMapByKey(this._issueFieldsToQuestions, issue?.field);
  }

  hasQuestionByName(question: string): boolean {
    return isSomeEnum(this._questions)(question);
  }

  goToQuestion(question: T): void {
    this.activeQuestionKey = question;
  }

  hasIssues(): boolean {
    if (!this._issueKey) {
      return false;
    }

    return this._issuesStore.hasIssueForKey(this._issueKey);
  }

  goToRelevantQuestion(): void {
    this.goToQuestion(
      this._tryGetQuestionFromUrl() || this._getRelevantQuestion() || this.firstQuestionWithIssue || this.lastQuestion,
    );
  }

  async resoveIssueIfNeeded(): Promise<void> {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issues = this._issuesStore.getIssuesForKey(this._issueKey);
    for (const issue of issues) {
      const question = getFromMapByKey(this._issueFieldsToQuestions, issue.field);
      if (question === this._activeQuestionKey) {
        await this._issuesStore.resolveIssue(issue.id);
      }
    }
  }

  goBack(): void {
    const prevQuestionKey = this._getPreviousQuestionKey();

    if (!prevQuestionKey) {
      throw new QuestionStoreError("Can‘t go back from the first question");
    }

    this.goToQuestion(prevQuestionKey);
  }

  goNext(): void {
    const nextQuestionKey = this._getNextQuestionKey();

    if (!nextQuestionKey) {
      throw new QuestionStoreError("Can‘t go forward from the last question");
    }

    this.goToQuestion(nextQuestionKey);
  }

  protected _shouldSkipQuestion(_0: T): boolean {
    return false;
  }

  protected _getPreviousQuestionKey(): T | undefined {
    return this._findNextRelevantQuestion(this.activeQuestionIndex - 1, "backward");
  }

  protected _getNextQuestionKey(): T | undefined {
    return this._findNextRelevantQuestion(this.activeQuestionIndex + 1, "forward");
  }

  private _findNextRelevantQuestion(startIndex: number, direction: "backward" | "forward"): T | undefined {
    let shift = direction === "backward" ? -1 : 1;
    let nextQuestionKey = this._questionsArray[startIndex];

    while (nextQuestionKey) {
      if (this._shouldSkipQuestion(nextQuestionKey)) {
        nextQuestionKey = this._questionsArray[startIndex + shift];
        shift = direction === "backward" ? shift - 1 : shift + 1;
        continue;
      }
      return nextQuestionKey;
    }

    return undefined;
  }

  private _tryGetQuestionFromUrl(): T | undefined {
    const questionFromParams = getUrlParam(registerFormStepParam);
    if (questionFromParams && this.hasQuestionByName(questionFromParams)) {
      return questionFromParams as T;
    }

    return undefined;
  }

  private _getQuestionsArray(): T[] {
    return Object.values(this._questions);
  }
}
