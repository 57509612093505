import { makeAutoObservable } from "mobx";

import { addressFormToCreateData } from "helpers/forms/address";
import type { AddressFormValue } from "helpers/forms/addressTypes";
import { ensured } from "helpers/value";
import type { AddressClient } from "services/address/addressClient";
import type { CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesClient } from "./companyBusinessActivitiesClient";
import {
  CompanyBusinessActivityCreateData,
  type CompanyBusinessActivityData,
  CompanyBusinessActivityDeleteData,
} from "./companyBusinessActivitiesTypes";

export class CompanyBusinessActivitiesStore {
  private _activities: CompanyBusinessActivityData[] = [];

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesClient: CompanyBusinessActivitiesClient,
    private readonly _addressClient: AddressClient,
  ) {
    makeAutoObservable(this);
  }

  get activities(): CompanyBusinessActivityData[] {
    return this._activities;
  }

  get hasActivities(): boolean {
    return this.activities.length !== 0;
  }

  hasActivityWithId(activityId: string): boolean {
    return this._activities.some((activity) => activity.businessActivityId === activityId);
  }

  async init(): Promise<void> {
    await this._getActivities();
  }

  async createActivity(activityId: string, addressData?: AddressFormValue): Promise<void> {
    this._activities = await this._companyBusinessActivitiesClient.createActivity(
      this._companyStore.getCompany().id,
      new CompanyBusinessActivityCreateData(activityId),
    );

    if (!addressData) {
      return;
    }

    const activity = ensured(this.getActivityWithId(activityId));
    await this._addressClient.createAddress(
      addressFormToCreateData(addressData, { companyActivityId: activity.companyActivityId }),
    );

    await this._getActivities();
  }

  async deleteActivity(activityId: string): Promise<void> {
    this._activities = await this._companyBusinessActivitiesClient.deleteActivity(
      this._companyStore.getCompany().id,
      new CompanyBusinessActivityDeleteData(activityId),
    );
  }

  async deleteAllActivies(): Promise<void> {
    for (const activity of this.activities) {
      await this.deleteActivity(activity.businessActivityId);
    }
  }

  getActivityWithId(activityId: string): CompanyBusinessActivityData | undefined {
    return this._activities.find((activity) => activity.businessActivityId === activityId);
  }

  private async _getActivities(): Promise<void> {
    const companyId = this._companyStore.company?.id;

    if (!companyId) {
      return;
    }

    this._activities = await this._companyBusinessActivitiesClient.getActivities(companyId);
  }
}
