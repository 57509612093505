import { type FC, type ReactNode, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useServices } from "appServices";
import { Page } from "pages";

interface UserFlowGuardProps {
  children: ReactNode;
}

export const UserFlowGuard: FC<UserFlowGuardProps> = ({ children }) => {
  const {
    userStore: { status },
    analyticsManager,
  } = useServices();
  const navigate = useNavigate();

  useEffect(() => {
    analyticsManager.event("user-state", {
      canProceed: status.canProceed,
      companies: status.companies.join(),
      blockedByOnboarding: status.blockedByOnboarding,
    });

    if (!status.canProceed) {
      navigate(Page.ExistedCustomer);
    }
  }, []);

  return <>{children}</>;
};
