import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum FlowType {
  Incorporation = "INCORPORATION",
  Abn = "ABN",
  Acn = "ACN",
  AcnAbn = "ACN_ABN",
}

@Serializable()
export class UserData {
  constructor(
    @JsonProperty() readonly alias: string,
    @JsonProperty({ type: FlowType, required: false }) readonly currentFlowType: Nullable<FlowType>,
  ) {}
}

@Serializable()
export class UserFlowTypeUpdateData {
  constructor(@JsonProperty({ type: FlowType }) readonly currentFlowType: FlowType) {}
}

@Serializable()
export class UserStatusData {
  constructor(
    @JsonProperty({ type: Boolean }) readonly canProceed: boolean,
    @JsonProperty({ type: String, isArray: true }) readonly companies: string[],
    @JsonProperty({ type: Boolean }) readonly blockedByOnboarding: boolean,
  ) {}
}
