import { AnnaError, ApiError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";

import { type StripeIntegrationClient } from "services/stripeIntegration/stripeIntegrationClient";
import { type PaymentMethod, type PaymentMethodResponseData } from "services/stripeIntegration/stripeIntegrationTypes";

interface PaymentMethodsLoadedState {
  paymentMethods: PaymentMethod[];
}

type PaymentMethodsState = "loading" | "error" | PaymentMethodsLoadedState;

export class PaymentMethodsStore {
  private readonly _stripeIntegrationClient: StripeIntegrationClient;

  private _state: PaymentMethodsState = "loading";

  constructor(stripeIntegrationClient: StripeIntegrationClient) {
    this._stripeIntegrationClient = stripeIntegrationClient;

    makeAutoObservable(this);
  }

  get state(): PaymentMethodsState {
    return this._state;
  }

  private set state(value: PaymentMethodsState) {
    this._state = value;
  }

  get hasActivePaymentMethod(): boolean {
    if (this.state === "loading" || this.state === "error") {
      return false;
    }

    return this.state.paymentMethods.some((item) => item.isActive);
  }

  async init(): Promise<void> {
    this.state = "loading";

    try {
      const paymentMethodsData = await this._getPaymentMethods();

      this.state = {
        paymentMethods: paymentMethodsData?.paymentMethods || [],
      };
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  private async _getPaymentMethods(): Promise<PaymentMethodResponseData | undefined> {
    try {
      return await this._stripeIntegrationClient.getPaymentMethods();
    } catch (e) {
      if (e instanceof ApiError) {
        // stripe-integration could answer with this status if we don't have a company_id yet
        if (e.diagnostics.httpCode === 400) {
          return;
        }
      }

      console.error(new AnnaError("Couldn't load payment methods", { cause: e }));

      return undefined;
    }
  }
}
