import { Loader as AnnaLoader } from "@anna-money/anna-web-ui";
import classNames from "classnames";

import type { FC } from "react";
import styles from "./Loader.module.scss";

export enum LoaderMode {
  Inline = "inline",
  Block = "block",
  Splashscreen = "splashscreen",
}

interface LoaderProps {
  className?: string;
  size?: "s" | "m" | "l" | "xl" | "xxl";
  align?: "left" | "center";
  mode?: LoaderMode;
}

export const Loader: FC<LoaderProps> = ({ className, size = "m", align = "left", mode = LoaderMode.Inline }) => {
  const loader = (
    <AnnaLoader
      className={classNames(styles.loader, styles[`mode-${mode}`], styles[`align-${align}`], className)}
      size={size}
    />
  );
  return mode === LoaderMode.Splashscreen ? <div className={styles.splashscreenWrapper}>{loader}</div> : loader;
};
