import { ApiError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";

import { type SumsubApplication } from "services/sumsub/sumsubTypes";
import { type SumsubClient } from "./sumsubClient";

export class SumsubApplicationStore {
  private readonly _sumsubClient: SumsubClient;
  private _sumsubApplication?: SumsubApplication;

  constructor(sumsubClient: SumsubClient) {
    this._sumsubClient = sumsubClient;

    makeAutoObservable(this);
  }

  get sumsubApplication(): SumsubApplication | undefined {
    return this._sumsubApplication;
  }

  set sumsubApplication(value: SumsubApplication | undefined) {
    this._sumsubApplication = value;
  }

  get isPassedVerifications(): boolean {
    return this._sumsubApplication?.isPassedVerifications ?? false;
  }

  async init(): Promise<void> {
    await this.getSumsubApplication();
  }

  async getSumsubApplication(): Promise<void> {
    try {
      this.sumsubApplication = await this._sumsubClient.getSumsubApplication();
    } catch (e) {
      if (e instanceof ApiError && e.code !== 1040) {
        throw e;
      }
    }
  }
}
