import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import type { AbnFormValues } from "helpers/forms/acn/abnTypes";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export type CompanyBusinessActivityFormValues = Pick<AbnFormValues, "businessActivities">;

export class MainBusinessActivityFormStore {
  constructor(private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore) {}

  @FormSubmitMethod()
  async onSubmit(values: CompanyBusinessActivityFormValues): Promise<FormSubmitResult> {
    if (!values.businessActivities?.length) {
      throw new FormError("No business activities value");
    }

    await this._companyBusinessActivitiesStore.deleteAllActivies();

    for (const activityValue of values.businessActivities) {
      if (!activityValue.address) {
        throw new FormError("No business activity address value");
      }

      await this._companyBusinessActivitiesStore.createActivity(activityValue.id, activityValue.address);
    }
  }
}
