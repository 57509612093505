import { type ComponentType } from "react";
import { type Config, Region } from "./config/config";
import { Page } from "./pages";
import { ExistedCustomerRoutes } from "./routes/ExistedCustomerRoutes";
import { AcnRegisterFormRoutes } from "./routes/au/AcnRegisterFormRoutes";
import { SignUpRoutes as SignUpRoutesAu } from "./routes/au/SignUpRoutes";
import { IncorporationRegisterFormRoutes } from "./routes/gb/IncorporationRegisterFormRoutes";
import { SignUpRoutes as SignUpRoutesGb } from "./routes/gb/SignUpRoutes";

type AppSection = {
  path: string;
  component?: ComponentType<{ path: string }>;
};

export type AppProfileConfig = {
  sections: AppSection[];
};

const getSectionPath = (page: Page): string => {
  return page + "/";
};

const commonSections = [
  {
    path: getSectionPath(Page.ExistedCustomer),
    component: ExistedCustomerRoutes,
  },
];

const configs: Record<Region, AppProfileConfig> = {
  [Region.GB]: {
    sections: [
      {
        path: getSectionPath(Page.Signup),
        component: SignUpRoutesGb,
      },
      {
        path: getSectionPath(Page.Main),
        component: IncorporationRegisterFormRoutes,
      },
      ...commonSections,
    ],
  },
  [Region.AU]: {
    sections: [
      {
        path: getSectionPath(Page.Signup),
        component: SignUpRoutesAu,
      },
      {
        path: getSectionPath(Page.Main),
        component: AcnRegisterFormRoutes,
      },
      ...commonSections,
    ],
  },
};

export function resolveAppProfileConfig(config: Config): AppProfileConfig {
  return configs[config.region];
}

type AppSectionWithComponent = AppSection & { component: ComponentType<{ path: string }> };

export function filterComponentSections(sections: AppSection[]): AppSectionWithComponent[] {
  return sections.filter((s) => s.component) as AppSectionWithComponent[];
}
