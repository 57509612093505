import { makeAutoObservable } from "mobx";
import { isSomeEnum } from "helpers/enum";
import { apiSerializer } from "helpers/serialization";
import { type CompanyStore } from "services/company/companyStore";
import { FormError } from "types/errors";
import { type CompanyProductsClient } from "./companyProductsClient";
import {
  CompanyProduct,
  CompanyProductChangeData,
  type CompanyProductData,
  type DefaultCompanyProductDetailsData,
  VirtualOfficeBillingPeriod,
  VirtualOfficeProductDetailsData,
  getProductDetailsType,
} from "./companyProductsTypes";

export class CompanyProductsStore {
  private _productsData: CompanyProductData[] = [];

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyProductsClient: CompanyProductsClient,
  ) {
    makeAutoObservable(this);
  }

  get productsData(): CompanyProductData[] {
    return this._productsData;
  }

  private set productsData(products) {
    this._productsData = products;
  }

  async init(): Promise<void> {
    const companyId = this._companyStore.company?.id;

    if (!companyId) {
      return;
    }

    this.productsData = await this._companyProductsClient.getProducts(companyId);
  }

  hasProduct(product: CompanyProduct): boolean {
    return typeof this._findProduct(product) !== "undefined";
  }

  getProductDetails(product: CompanyProduct.VirtualOffice): VirtualOfficeProductDetailsData | undefined;
  getProductDetails(product: CompanyProduct): DefaultCompanyProductDetailsData | undefined;
  getProductDetails(
    product: CompanyProduct,
  ): DefaultCompanyProductDetailsData | VirtualOfficeProductDetailsData | undefined {
    const productData = this._findProduct(product);
    if (!productData) {
      return;
    }

    const detailsType = getProductDetailsType(product);
    if (detailsType) {
      return apiSerializer.deserialize(productData.details, detailsType);
    }

    return productData.details;
  }

  async addProduct(product: CompanyProduct, data?: unknown): Promise<void> {
    switch (product) {
      case CompanyProduct.VirtualOffice: {
        const isBillingPeriod = isSomeEnum(VirtualOfficeBillingPeriod);
        if (!isBillingPeriod(data)) {
          throw new FormError("Wrong billing period for virtual office");
        }
        await this._addProduct(
          CompanyProduct.VirtualOffice,
          apiSerializer.serialize(new VirtualOfficeProductDetailsData(data)),
        );
        return;
      }
      default:
        await this._addProduct(product);
    }
  }

  async deleteProduct(product: CompanyProduct): Promise<void> {
    this.productsData = await this._companyProductsClient.deleteProduct(
      this._companyStore.getCompany().id,
      new CompanyProductChangeData(product, undefined),
    );
  }

  private async _addProduct(product: CompanyProduct, details?: Object): Promise<void> {
    this.productsData = await this._companyProductsClient.addProduct(
      this._companyStore.getCompany().id,
      new CompanyProductChangeData(product, details),
    );
  }

  private _findProduct(product: CompanyProduct): CompanyProductData | undefined {
    return this.productsData.find((productData) => productData.product === product);
  }
}
