const requiredUtmParam = "utm_source";
const defaultUtmSource = "anna_formation";

export function extractPageAnalytics(url: string): Record<string, string> {
  const searchParams = new URL(url).searchParams;

  if (!searchParams.has(requiredUtmParam)) {
    searchParams.set(requiredUtmParam, defaultUtmSource);
  }
  return Object.fromEntries(Array.from(searchParams.entries()));
}
