import { ApiError, ValidationApiError } from "@anna-money/anna-web-lib";
import { extractBackendValidationError } from "helpers/forms/errors";
import { FormError } from "types/errors";
import { FormValidationError } from "types/form";

export function FormSubmitMethod() {
  // todo fix types
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (_0: Object, _1: string, descriptor: TypedPropertyDescriptor<any>) => {
    if (typeof descriptor.value === "function") {
      const fn = descriptor.value;
      descriptor.value = async function (...args: unknown[]) {
        try {
          const result = await fn.apply(this, args);
          return result;
        } catch (error) {
          if (error instanceof ValidationApiError) {
            return extractBackendValidationError(error);
          }

          if (error instanceof ApiError || error instanceof FormError) {
            return new FormValidationError(error.message);
          }

          throw error;
        }
      };
    }
  };
}
