import { makeAutoObservable } from "mobx";
import type { UserClusterClient } from "services/userCluster/userClusterClient";
import { type UserCluster, UserClusterData } from "services/userCluster/userClusterTypes";
import type { UserClient } from "./userClient";
import { type FlowType, type UserData, UserFlowTypeUpdateData, type UserStatusData } from "./userTypes";

export interface IUserStore {
  user: UserData;
  updateCurrentFlow: (flow: FlowType) => Promise<void>;
}

export class UserStore implements IUserStore {
  private constructor(
    private _userClient: UserClient,
    private _clusterClient: UserClusterClient,
    private _user: UserData,
    private _status: UserStatusData,
    private _cluster: UserCluster,
  ) {
    makeAutoObservable(this);
  }

  get user(): UserData {
    return this._user;
  }

  private set user(value: UserData) {
    this._user = value;
  }

  get status(): UserStatusData {
    return this._status;
  }

  get cluster(): UserCluster {
    return this._cluster;
  }

  private set cluster(value: UserCluster) {
    this._cluster = value;
  }

  async updateCurrentFlow(flow: FlowType): Promise<void> {
    this.user = await this._userClient.updateFlowType(new UserFlowTypeUpdateData(flow));
  }

  async tryCreateCluster(cluster: UserCluster): Promise<void> {
    if (this.cluster) {
      return;
    }
    this.cluster = await this._clusterClient.createCluster(new UserClusterData(cluster));
  }

  static async create(userClient: UserClient, clusterClient: UserClusterClient): Promise<UserStore> {
    const [user, userFormationStatus, cluster] = await this._load(userClient, clusterClient);
    return new UserStore(userClient, clusterClient, user, userFormationStatus, cluster);
  }

  private static async _load(
    userClient: UserClient,
    clusterClient: UserClusterClient,
  ): Promise<[UserData, UserStatusData, UserCluster]> {
    const currentUser = await userClient.getUser();
    const userStatus = await userClient.getUserStatus();
    const clusterData = await clusterClient.getCluster();

    return [currentUser, userStatus, clusterData];
  }
}
