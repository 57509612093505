import { AnnaError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";
import { type FeatureClient } from "./featureClient";
import { type Feature, type FeatureSet, FeatureState } from "./featureTypes";

const OPTIONAL_DATA_TIMEOUT = 10000;

export class FeatureStore {
  private constructor(
    private readonly _client: FeatureClient,
    private _features: FeatureSet | null,
  ) {
    makeAutoObservable<FeatureStore, "_client">(this, { _client: false });
  }

  get features(): FeatureSet | null {
    return this._features;
  }

  hasFeature(featureName: Feature): boolean {
    return !!this._features && this._features[featureName] === FeatureState.Enabled;
  }

  async reload(): Promise<void> {
    const features = await FeatureStore._loadFeatures(this._client);
    if (features) {
      this._features = features;
    }
  }

  private static async _loadFeatures(client: FeatureClient): Promise<FeatureSet | null> {
    try {
      return await client.getFeatures(OPTIONAL_DATA_TIMEOUT);
    } catch (e) {
      console.error(new AnnaError("Failed to reload features", { cause: e }));
      return null;
    }
  }

  static async create(client: FeatureClient): Promise<FeatureStore> {
    const features = await FeatureStore._loadFeatures(client);
    return new FeatureStore(client, features);
  }
}
