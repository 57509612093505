import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type CompanyProductChangeData,
  type CompanyProductData,
  CompanyProductsData,
  FormationExpressStateData,
} from "./companyProductsTypes";

export class CompanyProductsClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getProducts(companyId: string): Promise<CompanyProductData[]> {
    const response = await this._apiClient.get(`api/formations/companies/${companyId}/product`);
    const companyProductsData = response.getData(CompanyProductsData);

    return companyProductsData.items;
  }

  async addProduct(companyId: string, data: CompanyProductChangeData): Promise<CompanyProductData[]> {
    const response = await this._apiClient.post(`api/formations/companies/${companyId}/product`, data);
    const companyProductsData = response.getData(CompanyProductsData);

    return companyProductsData.items;
  }

  async deleteProduct(companyId: string, data: CompanyProductChangeData): Promise<CompanyProductData[]> {
    const response = await this._apiClient.delete(`api/formations/companies/${companyId}/product`, data);
    const companyProductsData = response.getData(CompanyProductsData);

    return companyProductsData.items;
  }

  async getFormationExpressState(): Promise<FormationExpressStateData> {
    const response = await this._apiClient.get("api/formations/product/same-day-registration");
    return response.getData(FormationExpressStateData);
  }
}
