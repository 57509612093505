import { type ApiClient } from "@anna-money/anna-web-lib";

import { ProfileData } from "./profileTypes";

export class ProfileClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getProfile(): Promise<ProfileData> {
    const response = await this._apiClient.get("api/formations/user");

    return response.getData(ProfileData);
  }
}
